import { useEffect, useState } from "react"

export default function useOverlay (){
  const [overlayContent, setOverlayContent] = useState(null)
  const [isOverlay, setIsOverlay] = useState(false)

  const toggleOverlay = (children) => {
    if(children){
      setOverlayContent(children)
      setIsOverlay(true)
    }else{
      setOverlayContent(null)
      setIsOverlay(false)
    }
  }

  return {toggleOverlay, isOverlay, overlayContent}
}

