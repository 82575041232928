import { useState } from "react";

export default function useApi(api) {
  const [listOfData, setList] = useState([]);

  // const fetchData = (user) => {
  //   if (user) {
  //     api(user.uid).then(setList)
  //   } else {
  //     setList([])
  //   }
  // }
  const fetchData = async (...args) => {
    try{
      const list = await api(...args)
      setList(list)
    }catch(err){
      console.error(err)
    }
  }
  
  return {listOfData, fetchData}
}