import { useEffect, useState } from "react";
import { useParams } from "react-router";
import useApi from "../hooks/useApi";
import Tag from "../components/Tag";
import { Link } from "react-router-dom";
import LoadingScreen from "../page/LoadingScreen";
import { useAuth } from "../hooks/useAuth";
import BookingModal from "../components/BookingModal";
import placeholder from '../images/placeholder-avatar.png'
import CircularImage from "../components/CircularImage"
import useInput from "../hooks/useInput";
import { fsapi } from "../service/api";
import Button from "../components/Button";
import {handleBookingButton} from "../service/utility"
import { StarIcon, PhoneIcon, ChatIcon, CashIcon } from "@heroicons/react/solid";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import { toast } from "react-toastify";
import { BadgeCheckIcon } from "@heroicons/react/solid";
import ReactTooltip from "react-tooltip";
const PRICING_PLAN = [
  {
    id: '0',
    name: '即時訊息',
    price: 50,
    info: <ul className='list-disc list-inside mt-2'><li>以文字方式交流</li><li>一星期諮詢期</li></ul>
  },
  {
    id: '1',
    name: '全方位交流',
    price: 80,
    info: <ul className='list-disc list-inside mt-2'><li>以語音、視象及文字方式交流</li><li>一個月諮詢期</li></ul>
  },
  {
    id: '2',
    name: '面對面諮詢',
    price: 120,
    info: <ul className='list-disc list-inside mt-2'><li>與分享者線下會面</li></ul>
  }
]

const useViewingUser = (uid) => {
  const [viewingUser, setViewingUser] = useState(null)
  useEffect(()=>{
    (async () => {
      const user = await fsapi.getProfile(uid)
      setViewingUser(user)
   })();
  }, [uid])
  return viewingUser
}

const transformCurrentYear = (fromYear, toYear) => {
  if(fromYear===toYear==='9999'){
    return '現在'
  }else if(toYear==='9999'){
    return `${fromYear} - 現在`
  }else{
    return `${fromYear} - ${toYear}`
  }
}


export default function UserScreen({bookingList, ...props}) {
  let {userId} = useParams();
  const auth = useAuth()
  const user = useViewingUser(userId)
  const {register, handleSubmit, watch, getValues} = useForm();

  if(!user){
    return(<LoadingScreen />)
  }

  let data = bookingList.listOfData || []
  const isBooked = data.filter(b=>b.status==='accepted'||b.status==='pending').find(b=>b.toId===userId||b.fromId===userId)
  const isSelf = auth.profile.uid === user.uid

  const handleBookingPreview = () => {
    if(!getValues('pricing')){
      toast.error('請選擇分享時數')
    }
    if(!getValues('bookingMessage')){
      toast.error('請填寫你想詢問的內容')
    }
    handleSubmit(data=>handleBookingButton(auth.overlay, auth.user, user, PRICING_PLAN[data.pricing], data.bookingMessage))()
  }
  // toast(user.displayName)

  user.experience = user.experience?user.experience.sort((a,b)=>a.toYear<b.toYear):[]
  const academic = user.experience.filter(e=>e.category==='education')
  const experience = user.experience.filter(e=>e.category!=='education')


  let tags = (user.tags) ? user.tags.map(t => <Tag>{t}</Tag>) : ''

  return(
    <div className="max-w-screen-lg w-full space-x-6 flex flex-wrap md:flex-nowrap px-2">
      <div className="w-full md:w-2/3 space-y-6">
        <div className="flex bg-white rounded-md p-8 flex-wrap">
          <Link to={`/user/${user.aid || user.uid}`}>
            <CircularImage size="w-24 h-24" alt={user.displayName} src={user.profilePictureURL || placeholder} />
          </Link>

          <div className="space-y-2 ml-6 flex-1">
          {user.isVerified&&
            <>
              <BadgeCheckIcon className='w-6 h-6 block float-right opacity-75' data-tip="SHARLO已認證該用戶身份"/>
              {/* <ReactTooltip /> */}
            </>
          }
            <h2 className="text-2xl"><Link to={`/user/${user.aid || user.uid}`}>{user.displayName}</Link></h2>
            <span className="xl">{user.title}</span>
            <ul className="">{tags}</ul>
            {/* <div className="flex h-6"><StarIcon /><StarIcon /><StarIcon /><StarIcon /><StarIcon /> (32) </div> */}
            {/* <div className="flex h-6 space-x-8"><span><PhoneIcon className="h-6 inline" />16</span><span><StarIcon className="h-6 inline" />32</span><span><ChatIcon className="h-6 inline" />48</span></div> */}
          </div>
          <div className="flex-auto w-full mx-4 mt-4 space-y-4">
            <p>{user.bio}</p>
          </div>
        </div>
        <div className="flex flex-col bg-white rounded-md p-8 space-y-2">
          <h2>相關經驗</h2>
          <ol className='divide-y-2'>
            {experience.map((e,i)=>{
              return (
                  <li key={i} className='py-2'>
                    <h4>{e.title}</h4>
                    <div>{e.description}</div>
                    <div>{transformCurrentYear(e.fromYear, e.toYear)}</div>
                  </li>
              )
            })}
            {experience.length===0&&<span className='text-gray-500'>空白</span>}
          </ol>
        </div>
        <div className="flex flex-col bg-white rounded-md p-8 space-y-2">
          <h2>學歷</h2>
          <ol className='divide-y-2'>
          {academic.map(e=><li  className='py-2'><h4>{e.title}</h4><div>{e.description}</div> <div>{transformCurrentYear(e.fromYear, e.toYear)}</div></li>)}
          {academic.length===0&&<span className='text-gray-500'>空白</span>}
          </ol>
        </div>
        <div className="flex flex-col bg-white rounded-md p-8 space-y-2">
          <h2>評論(0)</h2>
          <p className="text-gray-500">暫時未有評論</p>
        </div>
      </div>
      
      <div className="hidden md:flex flex-col md:w-1/3 space-y-4 bg-white rounded-md px-8 pt-8 pb-14 sticky self-start top-0">
        <h2>分享方式</h2>


          {PRICING_PLAN.map((p, i)=>
           <>
             <label  htmlFor={`pricing${i}`} className={classNames(
                watch('pricing') == i ?'bg-teal-500 text-white':'bg-gray-100',
               'hover:text-white cursor-pointer hover:bg-teal-500 p-4 rounded-md')}>
                 <span className='font-medium text-lg'>{p.name}</span>
                 <span className='float-right'>
                   {i!==2&&<><span  className='line-through mx-2'>HKD${p.price}</span><span className='text-lg'>免費</span></>}
                   {i===2&&<><span className='mx-2 text-lg'>HKD${p.price}</span></>}
                 </span>
                 <p>{p.info}</p>
            </label>
             <input type="radio" name="pricing" id={`pricing${i}`} value={i} className="hidden" {...register('pricing', {required: true})}/>
          </>
          )}



          <h2>預約訊息</h2>
          <textarea className="resize-none border rounded-xs w-full h-32 mt-3" name="desciption" {...register('bookingMessage', {required: true})} placeholder="A South African-born American entrepreneur and businessman who founded X.com in 1999 (which later became PayPal), SpaceX in 2002 and Tesla Motors in 2003. Musk became a multimillionaire in his late 20s when he sold his start-up company, Zip2, to a division of Compaq Computers." />
          {!isBooked&&!isSelf&&<Button className="bg-teal-600 px-2 py-2 text-white font-medium rounded-md hover:bg-teal-700" onClick={handleBookingPreview}>立即預約</Button>}
          {isBooked&&!isSelf&&<Button className="cursor-not-allowed bg-teal-600 px-2 py-2 text-white font-medium rounded-md opacity-30">已預約</Button>}
          {isSelf&&<Button className="cursor-not-allowed bg-teal-600 px-2 py-2 text-white font-medium rounded-md opacity-30">不能預約</Button>}
        <span className="absolute text-gray-500 bottom-4 right-6" data-tip='配對後分享者沒有回應：全額退款'>
          <CashIcon className="h-4 w-4 inline" />退款保證</span>
        {/* <ReactTooltip/> */}
      </div>
      <ReactTooltip />
    </div>
  )
}