
import { Link } from 'react-router-dom'
import CircularImage from './CircularImage'
import Tag from './Tag'
import Button from './Button'
import { handleBookingStatusChange } from '../service/utility'
import { stringfyFsTimestamp } from '../service/utility'
import ProfileBasicInfo from './ProfileBasicInfo'
import { useAuth } from '../hooks/useAuth'

export default function MiniProfileItem({booking, showInvitation, ...props}) {
  const auth = useAuth()
  if(!booking) return(null)
  return (
    <>
    <div className="space-y-4 py-4">
      <ProfileBasicInfo user={booking}/>
      <div>{booking.lastMessage?.content||'This is the request message for the booking. Hello world.'}</div>
      <div className="flex justify-end gap-4 items-center">
        {showInvitation&&<Button
          className="text-xs rounded-md p-2 border-2 border-gray-500 text-gray-500 hover:border-teal-700 hover:text-teal-700 font-medium text-center"
          onClick={()=>{handleBookingStatusChange(auth, booking, 'accepted')}}
        >接受預約</Button>}
        <Button
          className="text-xs rounded-md p-2 border-2 border-gray-500 text-gray-500 hover:border-red-700 hover:text-red-700 font-medium text-center"
          onClick={()=>{handleBookingStatusChange(auth, booking, 'cancelled')}}
        >取消預約</Button>
      </div>
      <div className="text-right text-sm text-gray-700">發送日期: {stringfyFsTimestamp(booking.createdAt)}</div>
    </div>
    </>
  )
}