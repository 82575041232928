import ProfileListItem from "../components/ProfileListItem"
import { useParams } from "react-router";
import { fakeapi, fsapi } from "../service/api";
import SessionProfileAction from "../components/SessionProfileAction";
import ChatMessage from "../components/ChatMessage";
import useInput from "../hooks/useInput";
import { useEffect, useState } from "react";
import { useAuth } from "../hooks/useAuth";
import { db } from "../service/api";
import firebase from "firebase";
import useScript from "../hooks/useScript";

import { useHistory } from "react-router";
import { VideoCameraIcon, ArrowCircleRightIcon } from "@heroicons/react/solid";

import JitsiMeet from "../components/JitsiMeet"
import ProfileBasicInfo from "../components/ProfileBasicInfo"
import Button from "../components/Button";
import { sendMessage } from "../service/utility";

function VideoChatScreen(props) {
  useScript('https://meet.jit.si/external_api.js',props.bookingId ,props.displayName, props.handleVideoChatEvent)
  return(<div id="jitsi-meet"></div>)
}

const DisabledOverlay = () => {
  return(
    <div className="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-50 z-50"></div>
  )
}



export default function SessionScreen(props) {
  let { bookingId } = useParams();
  let auth = useAuth()
  let bookingList = props.bookingList
  // [TODO] investigate a better way to handle undefined bookingProfile while isAuthPending 
  let bookingProfile = bookingList.listOfData.find(x=>x.bookingId === bookingId) || []

  const VIDEO_CHAT_START_MESSAGE = '已進入視像通話房間🚪'
  const VIDEO_CHAT_END_MESSAGE = '已離開視像通話房間✨'

  const isDisabled = ['finished','cancelled','rejected'].includes(bookingProfile.status)

  const [messageList, setMessageList] = useState([])
  const [usingJitsi, setUsingJitsi] = useState(false)

  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory();

  const handleStartVideoChat = () => {
    setUsingJitsi(true)
    // db.collection("message").doc(bookingId).collection("messages").add({
    //   'uid': auth.user.uid,
    //   'content': VIDEO_CHAT_START_MESSAGE,
    //   'createdAt': firebase.firestore.FieldValue.serverTimestamp(),
    // }).then((doc)=>{
    //   console.log('message sent:', doc)
    // })
  }

  const handleVideoChatEvent = (e) => {
    setUsingJitsi(false)
    // db.collection("message").doc(bookingId).collection("messages").add({
    //   'uid': auth.user.uid,
    //   'content': VIDEO_CHAT_END_MESSAGE,
    //   'createdAt': firebase.firestore.FieldValue.serverTimestamp(),
    // }).then((doc)=>{
    //   console.log('message sent:', doc)
    // })
  }

  const handleFinishBooking = (e) => {
    auth.overlay.toggleOverlay(
    <div className="py-6">
      <h2>確認完成諮詢?</h2>
      <p>完成諮詢後需要重新預約才能與分享者聯絡</p>
      <div className='flex justify-end space-x-4 mt-6'>
        <Button onClick={()=>{auth.overlay.toggleOverlay()}}
        className="text-teal-600 font-bold">返回</Button>
        <Button isLoading={isLoading} onClick={()=>{
          setIsLoading(true);
          fsapi.changeBookingStatus(bookingId, 'finished', auth.profile).then(
          ()=>{
            auth.overlay.toggleOverlay()
            setIsLoading(false)
            history.push('/bookings')
          }
        )}}
        className='text-white text-sm px-4 py-3 bg-red-500 rounded-md hover:bg-red-700'>確認</Button>
      </div>
    </div>
    )
  }

  let messages = messageList.map((message)=>
    <ChatMessage key={message.messageId} message={message} bookingProfileUid={bookingProfile.uid}/>
  )  

  useEffect(() => {
    const unsubscribe = db.collection("message").doc(bookingId).collection("messages").orderBy('createdAt').onSnapshot((querySnapshot)=>{
      let messages = [];
      querySnapshot.forEach((doc) => {
        messages.push({
          messageId:doc.id,
          ...doc.data()
        });
      });
      setMessageList(messages)
      document.querySelector('.chatbox').scrollTop = document.querySelector('.chatbox').scrollHeight
      console.log("Messages: ", messages.map(x=>x.content).join(" | "));
    })
    return () => unsubscribe()
  }, [bookingId])
  
  const inputMessage = useInput('');
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      sendMessage(inputMessage.value, auth.profile, bookingId)
      inputMessage.setValue('')
    }
  }

  const handleSendButton = () => {
    sendMessage(inputMessage.value, auth.profile, bookingId)
    inputMessage.setValue('')
  }

  return (
    <div className="max-w-screen-lg w-full space-y-6">
      {/* <ProfileListItem key={bookingProfile.bookingId} user={bookingProfile} actionButtons={<SessionProfileAction booking={bookingProfile} bookingList={bookingList}/>} /> */}
      {/* {usingJitsi&&<VideoChatScreen displayName={auth.profile.displayName} bookingId={bookingId} onChange={handleVideoChatEvent}/>} */}
      {usingJitsi&&<JitsiMeet displayName={auth.profile.displayName} bookingId={bookingId} onChange={handleVideoChatEvent}/>}
      <div className="bg-white rounded-md p-8 space-y-4">
      {isDisabled&&<h2 className="float-right">已完成諮詢</h2>}
      
        <div className="self-center justify-between flex ">
        <ProfileBasicInfo user={bookingProfile} />
        {!isDisabled&&
          <div className='space-y-4 space-x-4'>
          <button className=" mt-4 text-white text-sm  px-4 py-3  bg-teal-600 rounded-md hover:bg-teal-700" onClick={handleStartVideoChat}>
          開始視像通話
        </button>
          <button className="bg-red-500 text-sm text-white px-4 py-3 rounded-md hover:bg-red-600" onClick={handleFinishBooking}>完成分享</button>
          </div>
          }

        </div>
        <div className='chatbox flex flex-col space-y-4 overflow-y-auto h-72'>
          {messages}
        </div>
        {!isDisabled&&
        <div className=''>

          <div className="flex flex-row mt-4">
            <input className="w-full focus:outline-none focus:border-b-2 focus:border-gray-200" type="text" placeholder="Press [Enter] to send a message" 
            value={inputMessage.value} onChange={inputMessage.onChange} onKeyDown={handleKeyDown}/><ArrowCircleRightIcon className="w-auto h-12 inline-flex self-center text-teal-600 cursor-pointer" onClick={handleSendButton}/>
          </div>
        </div>
        }
          {/* {isDisabled&&<DisabledOverlay />} */}
      </div>
    </div>
  )
}