import { useState } from "react"
import { useAuth } from "../hooks/useAuth"
import useInput from "../hooks/useInput"
import { createBooking } from "../service/api"
import Button from "./Button"
import ProfileBasicInfo from "./ProfileBasicInfo"

const ConfirmBookingModal = (props) => {
  const message = useInput()
  const [isLoading, setIsLoading] = useState(false)
  const auth = useAuth()

  const  handleBooking = async () => {
    if(isLoading)
      return
    setIsLoading(true);
    const createdBooking = await createBooking(props.fromUser, props.toUser, props.pricing, props.message)
    setIsLoading(false);
    if(createdBooking){
      console.log('created booking', createdBooking)
    }
    auth.overlay.toggleOverlay()
  }

  return(
    <div className="space-y-6 min-w-96 px-6">
      <h2>請確認分享預約</h2>
        <ProfileBasicInfo user={props.toUser}/>
        <p className="bg-gray-50 p-8 rounded-md">{props.message}</p>
        <p>分享方式: {`${props.pricing.name} @ HKD$${props.pricing.price}`}</p>
      <Button 
        isLoading={isLoading}
        onClick={handleBooking}
        className="float-right py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white  bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500">確認預約</Button>
    </div>
  )
}

export default ConfirmBookingModal