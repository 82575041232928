import { useEffect, useState } from "react";
import { WithContext as ReactTags } from 'react-tag-input';

const KeyCodes = {
  comma: 188,
  enter: [10, 13],
  // space: 32,
};

const delimiters = [...KeyCodes.enter, KeyCodes.comma, KeyCodes.space];

const TAGS = [
  { id: "HK", text: "HK" }
]

const SUGGESTIONS = [
  { id: 'HKU', text: 'HKU' },
  { id: 'CUHK', text: 'CUHK' },
  { id: 'HKUST', text: 'HKUST' },
  { id: 'CITYU', text: 'CITYU' },
  { id: 'POLYU', text: 'POLYU' },
  { id: 'APPLE', text: 'APPLE' },
  { id: 'APP', text: 'APP' },
]

const TagInput = ({register, setValue, placeholder, defaults=TAGS, ...props}) => {
  const [tags, setTags] = useState(defaults)
  const [suggestions, setSuggestions] = useState(SUGGESTIONS)
  console.log(tags)
  useEffect(()=>{
    // fetch and set suggestions
  }, [])

  useEffect(() => {
    register("tags", {required: true});
  }, [register]);

  useEffect(()=>{
    setValue("tags", tags);
  }, [tags])

  const handleDelete = (i) => {
    setTags(tags.filter((tag, index) => index !== i))
  }
  
  const handleAddition = (tag) => {
    tag.text = tag.text.replace(/\p{Sc}|\p{P}/gu, "").toUpperCase()
    tag.id = tag.text
    setTags([...tags, tag])
  }
  
  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();
  
    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);
  
    // re-render
    setTags(newTags)
    setValue("tags", tags);

  }

  return(
        <ReactTags 
            placeholder={placeholder}
            tags={tags}
            suggestions={suggestions}
            handleDelete={handleDelete}
            handleAddition={handleAddition}
            handleDrag={handleDrag}
            delimiters={delimiters}
            maxLength={20}
            inline={true} 
            autofocus={false}
            allowDeleteFromEmptyInput={false}
            inputFieldPosition='inline'/>
  )
}

export default TagInput