const Footer = (props) => {
  return(
    <div className='mt-auto pt-16 pb-8 bg-teal-100 px-2'>
      <div className='max-w-screen-lg mx-auto justify-between flex flex-wrap gap-4'>
        {/* <a href="/"><img className="h-8 w-auto" alt='SHARLO' src='/images/sharlo-logo.png' /></a> */}
        <div className='space-y-2 flex-auto w-full md:w-auto'>
          <img className='h-10' src='/sharlo-logo.png' />
          
          <div className='flex gap-2'>
            <img className='cursor-pointer opacity-75' src='/images/facebook.svg' alt='Facebook'/>
            <img className='cursor-pointer opacity-75' src='/images/instagram.svg' alt='Instagram'/>
            <img className='cursor-pointer opacity-75' src='/images/whatsapp.svg' alt='WhatsApp'/>
            <img className='cursor-pointer opacity-75' src='/images/email.svg' alt='Email'/>
          </div>


        </div>
        <div className=' md:flex-1'>
          <h3>經驗分享平台</h3>
          <ul>
            
            <li><a href="#">新手上路</a></li>
            <li><a href="#">分享者專區</a></li>
            <li><a href="#">常見問題</a></li>

          </ul>
        </div>
        <div className=' md:flex-1'>
          <h3>商業合作</h3>
          <ul>
            <li><a href="#">關於SHARLO</a></li>
            <li><a href="#">合作伙伴</a></li>
            <li><a href="#">工作機會</a></li>
          </ul>
        </div>
        <div className=' md:flex-1'>
          <h3>實用資訊</h3>
          <ul>
            <li><a href="#">網誌專欄</a></li>
          </ul>
        </div>
        <div className=' md:flex-1'>
          <h3>聯絡我們</h3>
          <div>support@sharlohk.com</div>
          {/* <div>2323 2323</div> */}
        </div>

      </div>
      <div className='max-w-screen-lg text-center mx-auto mt-16'>
          <ul className='flex gap-2 justify-center'>
              <li className='font-bold'>©SHARLO 2024</li>
              <li><a href="#">使用條款</a></li>
              <li><a href="#">隱私條款</a></li>
          </ul>
      </div>
    </div>
  )
}

export default Footer