export default function PaymentScreen(){
  return(
    <div className="container">
      <h2>Payment</h2>
      <div className="flex">
        <div>
          <h3>Package 1</h3>
          <ul>
            <li>5 bookings</li>
          </ul>
          <h3>Package 2</h3>
          <ul>
            <li>15 bookings</li>
          </ul>
          <h3>Package 1</h3>
          <ul>
            <li>25 bookings</li>
          </ul>
        </div>

      </div>
    </div>
  )
}