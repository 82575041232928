import React, { useContext, createContext, useState, useRef, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { ProvideAuth, useAuth } from "./hooks/useAuth";
// import { BOOKINGS, USERS, MESSAGES, db } from "./service/api";

import LoginScreen from "./page/LoginScreen";
import SearchScreen from "./page/SearchScreen";
import BookingScreen from "./page/BookingScreen";
import SessionScreen from "./page/SessionScreen";
import RegisterScreen from "./page/RegisterScreen";
import HomeScreen from "./page/HomeScreen";
import UserScreen from "./page/UserScreen";
import PaymentScreen from "./page/PaymentScreen";
import SettingsScreen from "./page/SettingsScreen";
import NotificationScreen from "./page/NotificationScreen";
import DesignSystem from "./page/DesignSystem";
import LoadingScreen from "./page/LoadingScreen";
import Footer from "./components/Footer";
import classNames from "classnames";

import Header from "./components/Header";

import firebase from "firebase";
import Overlay from "./components/Overlay";
import 'firebase/auth'

import { fsapi } from "./service/api";
import { useOverlay } from "./components/Overlay"; 

import useApi from "./hooks/useApi";
import VerificationScreen from "./page/VerificationScreen";

export default function App() {

  return (
    <>
    <ProvideAuth>
      <Router>
        <Header />
        <Main />
      </Router>
    </ProvideAuth>
    <Footer />
    </>
  );
}


function Main(){
  let auth = useAuth()
  let bookingList = useApi(fsapi.getBookingList)
  // console.log(bookingList)
  // let profileList = useApi(fsapi.getProfileList)
  // const [bookingList, setBookingList] = useState([])
  //[TODO] refactor to avoid 2 api calls while perserve onupdate while receive notification
  useEffect(()=>{
    if(!auth.isAuthPending && auth.user !== null){
      console.log('feteching bookingList')
      bookingList.fetchData(auth.user);
      // profileList.fetchData(999);
    }
  }, [auth])

  return(
      <div className={classNames(auth.overlay.isOverlay?'overflow-y-hidden':'','flex items-center justify-center mb-4')}>
        <Switch>
          {/* <Route path="/about">
            <About />
          </Route> */}
          {/* <Route path="/design">
            <DesignSystem />
          </Route> */}
          <PrivateRoute path="/user/:userId">
            <UserScreen bookingList={bookingList}/>
          </PrivateRoute>
          {/* <PrivateRoute path="/users">
            <Users />
          </PrivateRoute> */}
          <PrivateRoute path="/search">
            <SearchScreen bookingList={bookingList} updateBookingList={()=>{bookingList.fetchData(auth.user)}}/>
          </PrivateRoute>
          <PrivateRoute path="/booking/:bookingId">
            <SessionScreen bookingList={bookingList}/>
          </PrivateRoute>
          <PrivateRoute path="/bookings">
            <BookingScreen bookingList={bookingList}/>
          </PrivateRoute>
          <PrivateRoute path="/payment">
            <PaymentScreen />
          </PrivateRoute>
          <PublicRoute path="/login">
              <LoginScreen />
          </PublicRoute>
          <PublicRoute path="/register">
              <RegisterScreen />
          </PublicRoute>
          <PrivateRoute path="/settings">
            <SettingsScreen />
          </PrivateRoute>
          <PrivateRoute path="/notification">
            <NotificationScreen />
          </PrivateRoute>
          <PrivateRoute path="/verify">
            <VerificationScreen />
          </PrivateRoute>
          <PrivateRoute path="/" exact>
            <HomeScreen />
          </PrivateRoute>
          <PrivateRoute path="/404" exact>
            <div className="flex" style={{alignItems: 'center', paddingBottom: '20vh'}}>
              <h1>🤷‍♀️ 404 Not Found</h1>
            </div>
          </PrivateRoute>
          <PrivateRoute path="/">
            <Redirect to="/404" />
          </PrivateRoute>
        </Switch>
        <Overlay overlay={auth.overlay}/>
        
        <ToastContainer
          bodyClassName='font-medium'
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
  )
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({children, ...rest }) {
  let auth = useAuth();
  if(auth.isAuthPending){
    return(<LoadingScreen />)
  }else{
    return (
      <Route {...rest} render={({location, ...props}) => {
        if((auth.user&&auth.user.emailVerified) || (auth.user&&location.pathname ==='/verify')){
          return children
        }else if(auth.user){
          return <Redirect to={{pathname: "/verify", state: {from: location}}}/>
        }else{
          return <Redirect to={{pathname: "/login", state: {from: location}}}/>
        }
      }}/>
    );
  }
}

function PublicRoute({children, ...rest }) {
  let auth = useAuth();
  return (
    <Route
      {...rest}
      render={({props}) => !auth.user 
      ?  children
      : <Redirect to={{pathname: "/"}}/>}
    />
  );
}



function About() {
  return <h2>About</h2>;
}