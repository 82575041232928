import classNames from "classnames"
import { useState } from "react"

const CircularImage = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  return(
    <div className={classNames(props.size, 'flex relative justify-center overflow-hidden')}>
      <div className={classNames(props.size,isLoading?'visible':'hidden', 'absolute block bg-gray-200 animate-pulse rounded-full')}></div>
      <img className={classNames(props.size, isLoading?'opacity-0':'opacity-100',
      "block transition duration-500 ease-in-out rounded-full border-2 border-solid border-gray-200 z-10")}
        alt={props.alt} src={props.src} onLoad={()=>{setIsLoading(false)}} />
    </div>
  )
}

export default CircularImage