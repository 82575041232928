import { Link } from "react-router-dom"
import { SHARING_CATEGORIES } from "../service/utility";


const CategorySection = () => {
  return (
      <div className=' flex flex-wrap max-w-screen-lg mx-auto text-center justify-between gap-4'>
        {
          SHARING_CATEGORIES.map(c=>
            <div className='flex-auto bg-white px-6 py-6 rounded-2xl shadow-md'>
              <Link to={`/search?category=${c.value}`} >
                <img className='w-20 h-20 mx-auto' src={`/images/cat-${c.value}.svg`} alt={c.name}/>
                <span className='block mt-2 font-medium'>{c.name}</span>
              </Link>
            </div>
          )
        }
      </div>
  )
}

export default CategorySection