import { useEffect, useState } from 'react';


const useScript = (url, bookingId, displayName) => {

  const [api, setApi] = useState(null)

  useEffect(() => {
    const script = document.createElement('script');
    const domain = 'meet.jit.si';
    const options = {
      // [TODO] on request create UUID for roomName
      roomName: `sharlo-${bookingId}`,
      width: '100%',
      height: 700,
      parentNode: document.querySelector('#jitsi-meet'),
      userInfo: {
        displayName: displayName
      }
    }
    script.src = url;
    script.async = true;
    script.onload = function(){
      const jitsiAPI = new window.JitsiMeetExternalAPI(domain, options)
      setApi(jitsiAPI)
    }
    document.body.appendChild(script);

    return () => {
      if(api){
        api.dispose()
      }
      document.body.removeChild(script);
    }
  }, []);

  return api
};

export default function JitsiMeet(props) {
  const ROOM_PASSWORD = '18ZnIMTxS3Dg3KwnkLSVYg1hz2ZdXf3V7K'
  const api = useScript('https://meet.jit.si/external_api.js',props.bookingId ,props.displayName)

  if(api){
    api.addEventListener('participantRoleChanged', function(event) {
      // [TODO] on request create UUID for password
      if (event.role === "moderator") {
        api.executeCommand('password', ROOM_PASSWORD);
      }
    });

    // join a protected channel
    api.on('passwordRequired', function ()
    {
      api.executeCommand('password', ROOM_PASSWORD);
    });

    api.on('videoConferenceLeft', function (){
      console.log('videoConferenceLeft')
      props.onChange()
      api.dispose()
    })
  }
  return(<div id="jitsi-meet"></div>)
}