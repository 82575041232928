import ConfirmBookingModal from "../components/ConfirmBookingModal"
import ConfirmBookingStatusModal from "../components/modal/ConfirmBookingStatusModal"

import { db } from "../service/api";
import firebase from "firebase";

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ')
}

export const convertFsTimestamp = (firestoreTimestamp) => {
  if(!firestoreTimestamp)
    return
  return (new Date(firestoreTimestamp.seconds * 1000))
}

export const stringfyFsTimestamp = (firestoreTimestamp, tz={ timeZone: 'Asia/Hong_Kong' }) => {
  console.log('firestoreTimestamp',firestoreTimestamp)
  if(!firestoreTimestamp)
    return
  return convertFsTimestamp(firestoreTimestamp).toLocaleString('en-GB', tz).split(',')[0]
}

export const handleBookingButton = (overlay, fromUser, toUser, pricing, message) => {
  // BookingModal
  // overlay.toggleOverlay(<BookingModal fromUser={fromUser} toUser={toUser} />)
  // Confirm Booking Modal
  overlay.toggleOverlay(<ConfirmBookingModal fromUser={fromUser} toUser={toUser} pricing={pricing} message={message} />)
}

export const handleBookingStatusChange = (auth, booking, status) => {
  if(status==='accepted' || status==='cancelled'){
    auth.overlay.toggleOverlay(<ConfirmBookingStatusModal auth={auth} booking={booking} status={status}/>)
  }else{
    alert('Please contact our support team.')
  }
}

export const BOOKING_STATUS_MSG = {
  BOOKING_ACCEPTED:'預約成功',
}

export const getBookingMessage = (notification, currentUser) => {
  const uid = currentUser.uid
  const notiUserName = (uid !== notification.content.lastActionBy)?notification.content.lastActionByName:currentUser.displayName
  if(uid !== notification.content.lastActionBy){
    switch(notification.type){
      case 'NEW_MESSAGE':
        return `你收到來自 ${notiUserName} 的新訊息`
      case 'BOOKING_CREATED':
        return `${notiUserName} 向你發出分享請求`
      case 'BOOKING_ACCEPTED':
        return `${notiUserName} 已接受你的分享請求，你可到「我的預約」進行分享`
      case 'BOOKING_FINISHED':
        return `你跟 YYY 的分享已結束`
      case 'BOOKING_CANCELLED':
          return `${notiUserName}已取消你的分享請求`
      case 'BOOKING_REJECTED':
        return `${notiUserName}已拒絕你的分享請求`
      default:
        console.error('unknown notification type',notification)
        return `你有新的通知`
    }
  }else{
    switch(notification.type){
      case 'NEW_MESSAGE':
        return `你收到來自 ${notiUserName} 的新訊息`
      case 'BOOKING_CREATED':
        return `已向 ${notiUserName} 發出分享請求`
      case 'BOOKING_ACCEPTED':
        return `已接受 ${notiUserName} 的分享請求，你可到「我的預約」進行分享`
      case 'BOOKING_FINISHED':
        return `你跟 ${notiUserName} 的分享已結束`
      case 'BOOKING_CANCELLED':
          return `已取消向 ${notiUserName} 的分享請求`
      case 'BOOKING_REJECTED':
        return `已拒絕向 ${notiUserName} 的分享請求`
      default:
        console.error('unknown notification type',notification)
        return `你有新的通知`
    }
  }

}

// export const CATEGORIES = {
//   'study': '升學分享', 
//   'career': '工作就業', 
//   'business': '商務諮詢', 
//   'startup': '初創企業', 
//   'immigration': '海外移民',
//   'lifestyle': '生活風格'
// }

export const SHARING_CATEGORIES = [
  {
    name: '升學教育',
    value: 'study',
  },
  {
    name: '職場發展',
    value: 'career',
  },
  {
    name: '行業資訊',
    value: 'industry',
  },
  {
    name: '創業諮詢',
    value: 'startup',
  },
  {
    name: '生活態度',
    value: 'lifestyle',
  },
  {
    name: '其他項目',
    value: 'others',
  },
  
]

export const sendMessage = (message, profile, bookingId) => {
  const clearedMessage = message.trim()
  if(!clearedMessage){
    alert('Message is empty!')
    return true
  }
  db.collection("message").doc(bookingId).collection("messages").add({
    'uid': profile.uid,
    'content': clearedMessage,
    'createdAt': firebase.firestore.FieldValue.serverTimestamp(),
  }).then((doc)=>{
    console.log('message sent:', doc)
    db.collection("booking").doc(bookingId).update({
      lastActionBy: profile.uid,
      lastActionByName: profile.displayName,
      lastMessage: {
      'uid': profile.uid,
      'content': clearedMessage,
      'createdAt': firebase.firestore.FieldValue.serverTimestamp(),
    }})
  })
}