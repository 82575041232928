import { useEffect, useState } from "react"
import { useAuth } from "../hooks/useAuth"
import SimpleProfileItem from "../components/SimpleProfileItem"
import { CalendarIcon, CheckCircleIcon } from "@heroicons/react/solid"
import { Link, useHistory } from "react-router-dom"
import MiniProfileItem from "../components/MiniProfileItem"
import classNames from "classnames"
import CircularImage from "../components/CircularImage"
export default function BookingScreen(props) {
  let auth = useAuth()
  let bookingList = props.bookingList


  const [showInvitation, toggleInvitation] = useState(true)
  const handleToggleInvitation = () => {
    toggleInvitation(!showInvitation)
  }

  useEffect(()=>{
    bookingList.fetchData(auth.user)
    console.log('feteching listofdata')
  }, [])

  const acceptedBookings = bookingList.listOfData.filter(b=>b.status==='accepted')
  const pastBookings = bookingList.listOfData.filter(b=>b.status==='finished')
  const pendingFromBookings = bookingList.listOfData.filter(b=>b.status==='pending'&&b.fromId===auth.user.uid)
  const pendingToBookings = bookingList.listOfData.filter(b=>b.status==='pending'&&b.toId===auth.user.uid)
  let pendingBookings = showInvitation?pendingToBookings:pendingFromBookings

console.log(acceptedBookings)
  return(
    <div className="max-w-screen-lg flex space-x-4 w-full">
      <div className="w-2/3 space-y-4"> 
        <div className="space-y-8 bg-white rounded-md py-8">
          <h2 className="inline-block mx-8">進行中{}</h2>
          {bookingList.listOfData && acceptedBookings.map(b=><BookingItem key={b.bookingId} booking={b} currUser={auth.profile}/>)}
          {acceptedBookings.length===0 && <div className="text-center text-gray-400"><Link to='/search'><CalendarIcon className="w-6 h-6 inline" />未有相關預約，立即預約幫到你嘅分享者</Link></div>}
        </div>
        <div className="space-y-8 bg-white rounded-md py-8">
          <h2 className="inline-block mx-8">過往分享</h2>
          {bookingList.listOfData && pastBookings.map(b=><BookingItem key={b.bookingId} booking={b} currUser={auth.profile}/>)}
          {pastBookings.length===0 && <div className="text-center text-gray-400"><Link to='/search'><CalendarIcon className="w-6 h-6 inline" />未有相關分享</Link></div>}
        </div>
      </div>
      <div className="w-1/3 space-y-4 bg-white rounded-md divide-y-2 px-6">
          <div className='rounded-md pt-6 flex justify-evenly'>
            <span className={classNames(showInvitation?'text-teal-600 bg-teal-100':'text-gray-400 cursor-pointer','rounded-l-md justify-center p-2 bg-gray-50 font-bold flex-1 inline-flex text-md select-none')} onClick={handleToggleInvitation}>預約請求</span>
            <span className={classNames(!showInvitation?'text-teal-600 bg-teal-100':'text-gray-400 cursor-pointer','rounded-r-md justify-center p-2 bg-gray-50 font-bold flex-1 inline-flex text-md select-none')} onClick={handleToggleInvitation}>發出請求</span>
          </div>
          {bookingList.listOfData && pendingBookings.map(b=><MiniProfileItem key={b.bookingId} booking={b} hideTag={true} showInvitation={showInvitation}/>)}
          {pendingBookings.length===0 && <div className="text-center text-gray-400 pt-6"><Link to='/search'><CalendarIcon className="w-6 h-6 inline" />未有相關請求</Link></div>}
      </div>
    </div>
  )
}

const BookingItem = ({currUser, booking}) => {
  const history = useHistory()
  return(
    <div className='cursor-pointer hover:bg-gray-50 px-8' role='link' onClick={() => history.push('/booking/'+booking.bookingId)}>
      <div className="flex py-4">
          <CircularImage size="w-20 h-20" alt={booking.displayName} src={booking.profilePictureURL || '/placeholder/avatar.png'} />
        <div className="space-y-2 ml-6">
          <h3 className="inline-block text-xl">{booking.displayName}</h3><span className="ml-4">{booking.title}</span>
          <p>{booking.lastMessage?.uid===currUser.uid?<CheckCircleIcon className="w-4 h-4 inline mr-2 text-teal-500" />:null}{booking.lastMessage?.content}</p>
        </div>
      </div>
    </div>
  )
}