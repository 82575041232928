import React, { useState, useEffect, useContext, createContext } from "react";
import useOverlay from "./useOverlay";
import firebase from "firebase";
import "firebase/auth";
import "firebase/storage";
import 'firebase/firestore';

import { ToastContainer, toast } from 'react-toastify';

// import { db } from "../service/api";
// [TODO] invoke the firebase init without the need of importing the api here
import { firebaseConfig, getUserProfile } from "../service/api";
import { getBookingMessage } from "../service/utility";
import { useLocation } from "react-router-dom";

const authContext = createContext();
const db = firebase.firestore();
const storageURL = `https://storage.googleapis.com/${firebaseConfig.storageBucket}`

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [user, setUser] = useState(null)
  const [profile, setProfile] = useState(null)
  const [privateProfile, setPrivateProfile] = useState(null)

  const [isAuthPending, setAuthPending] = useState(true)
  const [notifications, setNotificationList] = useState([])
  // [TODO] considering useOverlay seperatly within each page components as the operation is not expensive at all
  const overlay = useOverlay()

  // Wrap any Firebase methods we want to use making sure ...
  // ... to save the user to state.
  const signin = (cb, email, password) => {
    return firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((response) => {
        setUser(response.user);
        cb();
        return response.user;
      });
  };

  const createTagFromList = async (tagList) => {
    for(const tag of tagList){
      try{
        const tagRef = db.collection("tag").doc(tag)
        let doc = await tagRef.get()
        if(doc.exists){
          doc = await tagRef.set({
            popularity: doc.data().popularity + 1
          })
        }else{
          doc = await tagRef.set({
            name: tag,
            popularity: 1
          })
        }
      }catch(err){
        console.error('createTagFromList', err)
      }
      console.log('createTagFromList added:', tag)
    }
  }

  const getUserPhotoURL = (isMasked, user) => {
        //[TODO] regenerate access token for photoURL
        // userProfileSchema.maskedItems.includes('profilePictureURL')
        // userProfileSchema.profilePictureURL
        if(isMasked){
          try{
            return storageURL + '/' + user.uid + '/publicPicture/' + user.photoURL.split('?alt')[0].split('%2F').splice(-1)[0]
          }catch(error){
            console.log('can\'t get infer publicPhotoURL', user.photoURL)
            return '//placeholder/avatar.png'
          }
        }else{
          return user.photoURL
        }
  }

  // TEST PW:GSdagh345h343
  // [TODO] refactor signup upload image process
  const signup = (cb, email, password, userProfile) => {
    console.log(email)
    console.log(password)
    console.log(userProfile)


    return firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((response) => {
        var user = firebase.auth().currentUser;
        const photoFileName = user.uid + '/profilePicture/' + (+ new Date()).toString() + '.' + userProfile.profileImageFile.name.split('.').slice(-1)[0]
        var storageRef = firebase.storage().ref(photoFileName);
        var uploadTask = storageRef.put(userProfile.profileImageFile)
        console.log('uploading invoked')
        let tagList = userProfile.tags.map(x=>x.text.toUpperCase())
        createTagFromList(tagList)
        let userProfileSchema = {
              uid: user.uid,
              firstName: userProfile.firstName,
              lastName: userProfile.lastName,
              displayName: userProfile.displayName,
              isAdviser: userProfile.isAdviser,
              category: userProfile.category,
              isVerified: userProfile.isVerified,
              profilePictureURL: '//placeholder/avatar.png',
              title: userProfile.title,
              tags: tagList,
              bio: userProfile.bio,
              experience: userProfile.experience,
              rating: -1,
              popularity: -1,
              maskedItems: [],
            }

        let privateProfileSchema = {
          phoneNumber: userProfile.phoneNumber
        }

        uploadTask.on('state_changed', 
          (snapshot) => {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
              case firebase.storage.TaskState.PAUSED: // or 'paused'
                console.log('Upload is paused');
                break;
              case firebase.storage.TaskState.RUNNING: // or 'running'
                console.log('Upload is running');
                break;
            }
          }, 
          (error) => {
            console.error(error)
            db.collection("userProfile")
            .doc(user.uid)
            .set({
              ...userProfileSchema,
              profilePictureURL: '//placeholder/avatar.png'
            }).then(()=>{
              setUser(response.user);
              setProfile({
                ...userProfileSchema,
                profilePictureURL: '//placeholder/avatar.png'
              })
              
              updatePrivateProfile(privateProfileSchema, cb)
              user.updateProfile({displayName: userProfile.displayName}).then(()=>{
                user.sendEmailVerification({url:`https://${window.location.host}`}).then(toast('已發送認證電郵'))
              })
              
              // cb()
            })    
          }, 
          () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
              console.log('File available at', downloadURL);
              // const publicPhotoURL = `${storageURL}/${photoFileName}`
              const publicPhotoURL = downloadURL
              db.collection("userProfile")
              .doc(user.uid)
              .set({
                ...userProfileSchema,
                profilePictureURL: publicPhotoURL
              }).then(()=>{
                setUser(response.user);
                setProfile({
                  ...userProfileSchema,
                  profilePictureURL: publicPhotoURL
                })
                updatePrivateProfile(privateProfileSchema, cb)
                user.updateProfile({displayName: userProfile.displayName, photoURL: downloadURL}).then(()=>{
                  user.sendEmailVerification({url:`https://${window.location.host}`}).then(toast('已發送認證電郵'))
                })
                // user.updateProfile({displayName: userProfile.displayName})
                // cb()
              })
            });
          }
        );
  
      }).catch(err=>console.log(err))
  };


  const updateProfile = (userProfile, cb=()=>{}) => {

    var user = firebase.auth().currentUser;
    let userProfileSchema = {}
    console.log(userProfile.tags)
    if(userProfile.tags&&typeof(userProfile.tags[0])==='object'){
      let tagList = userProfile.tags.map(x=>x.text.toUpperCase())
      createTagFromList(tagList)
      userProfileSchema = {
            firstName: userProfile.firstName,
            lastName: userProfile.lastName,
            displayName: userProfile.displayName,
            isAdviser: userProfile.isAdviser,
            category: userProfile.category,
            isVerified: userProfile.isVerified,
            title: userProfile.title,
            tags: tagList,
            bio: userProfile.bio,
            experience: userProfile.experience,
            maskedItems: userProfile.maskedItems?userProfile.maskedItems:[],
      }
    }else{
      userProfileSchema = {
        firstName: userProfile.firstName,
        lastName: userProfile.lastName,
        displayName: userProfile.displayName,
        isAdviser: userProfile.isAdviser,
        category: userProfile.category,
        isVerified: userProfile.isVerified,
        title: userProfile.title,
        tags: userProfile.tags,
        bio: userProfile.bio,
        experience: userProfile.experience,
        maskedItems: userProfile.maskedItems?userProfile.maskedItems:[],
      }
    }

    const realName = `${userProfile.firstName} ${userProfile.lastName}`

    if(userProfileSchema.maskedItems.includes('displayName')){
      userProfileSchema.displayName = 'A SHARLO User'
    }else{
      userProfileSchema.displayName = realName
    }


    //[TODO] refactor this and reuse it in signup()
    // user upload new profile image
    if(userProfile.profileImageFile&&userProfile.profileImageFile[0]){
      const photoFileName = user.uid + '/profilePicture/' + (+ new Date()).toString() + '.' + userProfile.profileImageFile[0].name.split('.').slice(-1)[0]
      var storageRef = firebase.storage().ref(photoFileName);
      var uploadTask = storageRef.put(userProfile.profileImageFile[0])

      uploadTask.on('state_changed', 
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              console.log('Upload is paused');
              break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
              console.log('Upload is running');
              break;
          }
        }, 
        (error) => {
          console.error(error)
          db.collection("userProfile")
          .doc(user.uid)
          .update({
            ...userProfileSchema,
            profilePictureURL: '//placeholder/avatar.png'
          }).then(()=>{
            // setUser(user);
            setProfile({
              ...userProfileSchema,
              profilePictureURL: '//placeholder/avatar.png'
            })
            user.updateProfile({displayName: realName})
            cb()
          })    
        }, 
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then(async (downloadURL) => {
            console.log('File available at', downloadURL);
            const result = await user.updateProfile({displayName: realName, photoURL: downloadURL})
            db.collection("userProfile")
            .doc(user.uid)
            .update({
              ...userProfileSchema,
              profilePictureURL: getUserPhotoURL(userProfileSchema.maskedItems.includes('profilePictureURL'), user)
            }).then(()=>{
              // setUser(user);
              setProfile({
                ...userProfileSchema,
                profilePictureURL: getUserPhotoURL(userProfileSchema.maskedItems.includes('profilePictureURL'), user)
              })
              // user.updateProfile({displayName: userProfile.displayName})
              cb()
            })
          });
        }
      );
    }else{
      db.collection("userProfile").doc(user.uid).update({
        ...userProfileSchema,
        profilePictureURL: getUserPhotoURL(userProfileSchema.maskedItems.includes('profilePictureURL'), user)
      }).then(()=>{
        user.updateProfile({displayName: realName})
        setProfile({
          ...profile,
          ...userProfileSchema,
          profilePictureURL: getUserPhotoURL(userProfileSchema.maskedItems.includes('profilePictureURL'), user)
        })
        cb()
      })   
    }
  }

  // private profile sotring the real info for user
  const updatePrivateProfile = async (privateProfile={phoneNumber:undefined, photoURL:undefined, realName:undefined}, cb) => {
    const user = firebase.auth().currentUser;
    const newPrivateProfile = Object.entries(privateProfile).reduce((a,[k,v]) => (!v ? a : (a[k]=v, a)), {})
    console.log('updatePrivateProfile',privateProfile)
    try{
      await db.collection("privateProfile").doc(user.uid).set(newPrivateProfile,{ merge: true })
      console.log('updatePrivateProfile', newPrivateProfile)
      setPrivateProfile({
        ...newPrivateProfile
      })
      if(typeof(cb)==='function')
        cb()
    }catch(err){
      console.log('updatePrivateProfile', err)
    }
  }

  // const updatePrivacy = (maskedItems, cb=()=>{}) => {
  //   const user = firebase.auth().currentUser;

  //   db.collection("userProfile").doc(user.uid).update({displayName:displayName,maskedItems: maskedItems}).then(()=>{
  //         cb()
  //         setProfile({...profile,displayName:displayName, maskedItems:maskedItems})
  //         console.log('updatedPrivacy', {...profile,displayName:displayName, maskedItems:maskedItems})
  //       }
  //     )
  // }
  
  const signout = (cb) => {
    return firebase
      .auth()
      .signOut()
      .then(() => {
        setUser(false);
        if(typeof(cb) === 'function')
          cb()
      });
  };

  const sendPasswordResetEmail = (email) => {
    return firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        return true;
      });
  };

  const confirmPasswordReset = (code, password) => {
    return firebase
      .auth()
      .confirmPasswordReset(code, password)
      .then(() => {
        return true;
      });
  };

  const setNotificationRead = (id) => {
    if(!id)
      return
    db.collection('notification').doc(id).update({is_read: true})
  }

  const setAllNotificationRead = () => {
    for(const n of notifications){
      if(!n.is_read)
        db.collection('notification').doc(n.id).update({is_read: true})
    }
  }

  const setNotificationShown = (id) => {
    if(!id)
      return
    db.collection('notification').doc(id).update({is_shown: true})
  }

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  useEffect(() => {
    let unsubNoti = null
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        try{
          setUser(user);

          const privateProfile = await db.collection("privateProfile").doc(user.uid).get();
          setPrivateProfile(privateProfile.data())

          const userProfile = await db.collection("userProfile").doc(user.uid).get();
          setProfile(userProfile.data())

          // [TODO] check if this listener will be cleaned up even after unmount
          unsubNoti = db.collection('notification').where('receiver','==',user.uid).orderBy('created_at','desc').onSnapshot((querySnapshot)=>{
            let notifications = [];
            querySnapshot.forEach((doc) => {
              notifications.push({
                id: doc.id,
                ...doc.data()
              });
            });
            setNotificationList(notifications)
            const lastestNotification = notifications.find(x=>!x.is_read&&!x.is_shown)
            if(lastestNotification){
              const path = document.location.pathname
              const bookingId = path.indexOf('/booking/')>=0?path.split('/')[2]:null
              console.log('notification', bookingId,lastestNotification.content.bookingId,lastestNotification.type)
              if(bookingId !== lastestNotification.content.bookingId || lastestNotification.type!=='NEW_MESSAGE'){
                toast(`${getBookingMessage(lastestNotification, userProfile.data())}`)
              }
              else{
                setNotificationRead(lastestNotification.id)
              }
              setNotificationShown(lastestNotification.id)
            }
            console.log("notificaiton", notifications)
          })
          console.log('useAuth',{...user,...userProfile})
        }catch(err){
          console.error('useAuth', err)
        }finally{
          setAuthPending(false)
        }
      } else {
        setUser(null)
        setProfile(null)
        setPrivateProfile(null)
        setAuthPending(false)
      }
    });

    // Cleanup subscription on unmount
    return () => {unsubscribe();if(unsubNoti)unsubNoti();}
  }, []);

  // Return the user object and auth methods
  return {
    user,
    privateProfile,
    profile,
    updateProfile,
    updatePrivateProfile,
    notifications,
    setNotificationRead,
    setAllNotificationRead,
    isAuthPending,
    signin,
    signup,
    signout,
    sendPasswordResetEmail,
    confirmPasswordReset,
    overlay,
  };
}