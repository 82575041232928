import placeholer from '../images/placeholder-avatar.png'
import { Link } from 'react-router-dom'

export default function ProfileListItem(props){
  let user = props.user
  let actionButtons = props.actionButtons
  //[TODO] fix unknown bug casuing this to be render when user.tags is undefined
  let tags = (user.tags)?user.tags.map(t=><li>{t}</li>):''
  return(
    <div className="profile-item flex">
          <picture>
            <img alt="profile" src={user.profilePictureURL||placeholer} />
          </picture>
          <div className="profile-info">
          <span><Link to={`/user/${user.aid||user.uid}`}>{user.displayName}</Link></span><span>{user.title}</span>
            <p>{user.bio}</p>
            <ul className="tag-list">{tags}</ul>
          </div>
          {actionButtons}
        </div>
  )
}