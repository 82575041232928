import { Link } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import sharloLogo from '../images/sharlo-logo.png'
import { useHistory } from "react-router";
import CircularImage from "./CircularImage";
import SearchBar from "./SearchBar";
import { useLocation } from "react-router";

const Header = () => {
  const auth = useAuth()
  const location = useLocation();
  let headerClasses = (auth.user&&auth.user.emailVerified)?'bg-white shadow-xs py-2 mb-4 select-none':'flex hidden';

  return(
    <header className={headerClasses}>
      <div className="flex items-center justify-between max-w-screen-lg mx-auto px-2">
      <Link to="/"><img className="h-8 w-auto" alt='SHARLO' src={sharloLogo} /></Link>
      {location.pathname!=='/'&&<SearchBar className="flex-1 h-10 inlie-block rounded-l-md border-2 border-solid border-gray px-4 py-2 w-full md:w-96 mx-8" />}
        <nav className="mx-8 flex-1">
            <ul className='flex space-x-4 justify-end'>
              <li className='hidden md:block'>
                <Link to="/search">尋找分享者</Link>
              </li>
              <li>
                <Link to="/bookings">我的預約</Link>
              </li>
            </ul>
        </nav>
        <ProfileMenu auth={auth} />
      </div>
  </header>
  )
}

function ProfileMenu({auth}) {
  const history = useHistory();
  // useEffect(()=>{
  //   alert('rendered as auth.profile is changed')
  // }, [auth.profile])
  return auth.user ? (
    <div className='group relative'>
      <CircularImage size="w-16 h-16 cursor-pointer" alt={auth.user.displayName||'user'} src={auth.user && auth.user.photoURL} />
      {/* <div className=" flex justify-center w-16 h-16">
          <img className="block rounded-full border-2 border-solid border-gray-300 " alt={auth.user.displayName||'user'} src={auth.profile && auth.profile.profilePictureURL} />
      </div> */}
      <div className='hidden group-hover:block absolute right-0 text-lg bg-white border border-gray-50 shadow-md rounded-md z-10 w-48'>
        <ul className="pt-4 pb-4 space-y-2">
        <Link to={`/user/${auth.user.uid}`}><li className="text-base font-medium px-8">{auth.profile&& (auth.user.displayName||auth.user.email)}</li></Link>
        <Link to={`/user/${auth.user.uid}`}><li className="text-base font-medium px-8">的個人頁面</li></Link>
          <hr />
          <Link to="/notification" className="block cursor-pointer hover:bg-gray-50 px-8"><li>通知</li></Link>
          <Link to="/settings" className="block cursor-pointer hover:bg-gray-50 px-8"><li>設定</li></Link>
          <li className="cursor-pointer hover:bg-gray-50 px-8" onClick={() => {auth.signout(() => history.push("/"));}}>登出</li>
        </ul>
      </div>
    </div>
  ) : (
    <button
        onClick={() => {
           history.push("/");
        }}
      >
        Sign In
      </button>
  );
}

export default Header