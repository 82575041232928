import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/analytics";

export const firebaseConfig = {
  apiKey: "AIzaSyA27_abrPddF8UgrngDnJMFgpz9UtFT5H8",
  authDomain: "sharlo-71df5.firebaseapp.com",
  projectId: "sharlo-71df5",
  storageBucket: "sharlo-71df5.appspot.com",
  messagingSenderId: "455725167209",
  appId: "1:455725167209:web:649ae430e6931f7b5928f8",
  measurementId: "G-KLTWPXPD19"
};
// Initialize Firebase
if (firebase.apps.length === 0) {
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();
}

export const db = firebase.firestore();

export const getUserProfile = (user) => {
  var userProfileRef = db.collection("userProfile").doc(user.id);
  return userProfileRef.get()
}

export const fsapi = {
  // return all bookings related to User: uid

  getBookingList: async function (user) {
    if(!user){
      return []
    }
    const uid = user.uid
    const bookings = []

    let fromBookings = await db.collection("booking").where('fromId', "==", uid).orderBy('updatedAt','desc').get()
    for(const doc of fromBookings.docs){
      const booking = doc.data()
      const bookingId = doc.id
      //[TODO] refactoring, extract add user profile function
      const adviser = await db.collection('userProfile').doc(booking.toId).get()
      const bookingProfile = {
        bookingId: bookingId,
        ...booking,
        ...adviser.data()
      }
      bookings.push(bookingProfile)
      // console.log('fsapi pushed 1 from booking', bookingProfile)
    }

    console.log('fsapi getting to booking')
    let toBookings = await db.collection("booking").where('toId', "==", uid).orderBy('updatedAt', 'desc').get()
    for(const doc of toBookings.docs){
      const booking = doc.data()
      const bookingId = doc.id
      //[TODO] refactoring, extract add user profile function
      const adviser = await db.collection('userProfile').doc(booking.fromId).get()
      const bookingProfile = {
        bookingId: bookingId,
        ...booking,
        ...adviser.data()
      }
      bookings.push(bookingProfile)
      // console.log('fsapi pushed 1 to booking', bookingProfile)
    }

    // await db.collection("booking")
    //   .where('fromId', "==", uid)
    //   .get()
    //   .then((querySnapshot) => {
    //     querySnapshot.forEach((doc) => {
    //       // doc.data() is never undefined for query doc snapshots
    //       const booking = doc.data()
    //       const bookingId = doc.id
    //       const adviserRef = db.collection('userProfile').doc(booking.toId)
    //       adviserRef.get()
    //         .then(doc => {
    //           console.log('fsapi getting from booking')
    //           bookings.push({
    //             bookingId: bookingId,
    //             ...booking,
    //             ...doc.data()
    //           })
    //         })
    //         .catch(err => console.error(err))
    //     });
    //   })
    //   .catch((error) => {
    //     console.log("Error getting documents: ", error);
    //   });

    // await db.collection("booking")
    //   .where('toId', "==", uid)
    //   .get()
    //   .then((querySnapshot) => {
    //     querySnapshot.forEach((doc) => {
    //       // doc.data() is never undefined for query doc snapshots
    //       const booking = doc.data()
    //       const bookingId = doc.id
    //       // get booking fromUser info 
    //       const adviserRef = db.collection('userProfile').doc(booking.fromId)
    //       adviserRef.get()
    //         .then(doc => {
    //           console.log('fsapi getting to booking')
    //           bookings.push({
    //             bookingId: bookingId,
    //             ...booking,
    //             ...doc.data()
    //           })
    //         })
    //         .catch(err => console.error(err))
    //     });
    //     // console.log(bookings)
    //   })
    //   .catch((error) => {
    //     console.error("Error getting documents: ", error);
    //   });

    console.log('fsapi returning all booking', bookings.length)
    console.log(bookings.map((b)=>b.bookingId))
    return bookings
  },
  getProfileList: async function (limit) {
    if(limit==='undefined'){
      limit=999
    }
    // console.log('limit = ', limit)
    let profiles = []
    await db.collection("userProfile")
      .limit(limit)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          profiles.push(doc.data())
        });
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
  },
  getProfile: async function (uid) {
    let user = false
    if(uid){
      try{
        let doc = await db.collection("userProfile").doc(uid).get()
        user = doc.data()
        console.log("getProfile", user)

      }catch(err){
        console.error('getProfile', err)
      }
    }
    return user
  },

    //[TODO] Deprecated
  removeBooking: async function (bookingId) {
    await db.collection('booking').doc(bookingId).delete()
  },

    //[TODO] Deprecated
  acceptBooking: async function (bookingId) {
    await db.collection('booking').doc(bookingId).update({status: 'accepted'})
  },

    //[TODO] Deprecated
  rejectBooking: async function (bookingId) {
    await db.collection('booking').doc(bookingId).update({status: 'rejected', updatedAt: firebase.firestore.FieldValue.serverTimestamp()})
  },
  //[TODO] Deprecated
  finishBooking: async function (bookingId, user) {
    await db.collection('booking').doc(bookingId).update({status: 'finished', updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    lastActionBy: user.uid,
    lastActionByName: user.displayName })
  },

    //[TODO] Deprecated
  cancelBooking: async function (bookingId) {
    await db.collection('booking').doc(bookingId).update({status: 'cancelled', updatedAt: firebase.firestore.FieldValue.serverTimestamp()})
  },
  changeBookingStatus: async function (bookingId, status, user) {
    console.log('changeBookingStatus',bookingId, status, user.uid)
    return await db.collection('booking').doc(bookingId).update({
      status: status, 
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      lastActionBy: user.uid,
      lastActionByName: user.displayName})
  },
}


export const createBooking = async (fromUser, toUser, pricing, message) => {
console.log(createBooking, fromUser, toUser, pricing, message)
  const data = {
    fromId: fromUser.uid,
    fromUser: fromUser.displayName,
    toId: toUser.uid,
    toUser: toUser.displayName,
    lastMessage: {uid: fromUser.uid, content: message},
    plan: {name:pricing.name, price:pricing.price},
    status: 'pending',
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    lastActionBy: fromUser.uid,
    lastActionByName: fromUser.displayName
  }

  try{
    let newBooking = await db.collection("booking").add(data)
    let newMessage = await db.collection("message").doc(newBooking.id)
    .collection('messages').add({
      uid: fromUser.uid, 
      content: message,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    })
    return newBooking.id
  }catch(err){
    console.error(err)
  }
}
