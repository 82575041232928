import { useForm, useFieldArray } from "react-hook-form"
import { useEffect, useState } from "react"
import { useAuth } from "../hooks/useAuth"
import { classNames, SHARING_CATEGORIES } from "../service/utility"

import {UserCircleIcon, BellIcon, CreditCardIcon, MinusCircleIcon, PlusCircleIcon} from '@heroicons/react/solid'

import ImageInput from "../components/form/ImageInput"
import TagInput from "../components/form/TagInput"

import Button from "../components/Button"

import { toast } from "react-toastify"
import YearPicker from "../components/form/YearPicker"

import { stringfyFsTimestamp } from "../service/utility"

const SETTINGS_TAB_PROFILE = 0
const SETTINGS_TAB_PRIVACY = 1
const SETTINGS_TAB_NOTIFICATION = 2
const SETTINGS_TAB_PAYMENT = 3

const EXPERIENCE_FIELD_PLACEHOLDER = {
  'job': {title: '職位', description: '公司/機構'},
  'education': {title: '課程', description: '學校/機構'},
  'others': {title: '簡稱', description: '公司/機構/學校'},
}

const SettingsScreen = (props) => {
  const [settingsTab, setSettingsTab] = useState(SETTINGS_TAB_PROFILE)

  const handleSettingsTabClick = (e) => {
    e.preventDefault()
    setSettingsTab(parseInt(e.target.getAttribute('data-key')))
  }

  let settingsFragment = null
  let title = '個人資料'
  
  switch(parseInt(settingsTab)){
    case SETTINGS_TAB_PROFILE:
      title = '個人資料'
      settingsFragment = <EditProfileScreen pageTitle={title}/>
      break;
    case SETTINGS_TAB_PRIVACY:
        settingsFragment = <EditPrivacyScreen pageTitle={title}/>
        title = '付款方式'
        break;
    case SETTINGS_TAB_NOTIFICATION:
      settingsFragment = <EditNotificationScreen pageTitle={title}/>
      title = '通知提示'
      break;
    case SETTINGS_TAB_PAYMENT:
      settingsFragment = <EditPaymentScreen pageTitle={title}/>
      title = '付款方式'
      break;
    default:
      title = 'Error'
      settingsFragment = <p>Error</p>
  }

  return (
    <div className="max-w-screen-lg space-y-4 w-full mb-16">
      {/* <h2>賬戶設定</h2>
      <span>更改你的個人資料、提示方式、付款方法</span> */}
      <div className="bg-white rounded-md p-8 flex">
        <aside className="w-1/5 flex-shrink-0 border-r-2 border-gray-300 select-none">
          <ul className='space-y-4'>
            <li className={classNames(
              settingsTab===SETTINGS_TAB_PROFILE?'text-gray-900':'',
              'cursor-pointer text-lg text-gray-500 flex')
             } data-key={SETTINGS_TAB_PROFILE} onClick={handleSettingsTabClick}><UserCircleIcon className='w-6 h-6 mr-1 inline-flex'/>個人資料</li>
            <li className={classNames(
              settingsTab===SETTINGS_TAB_PRIVACY?'text-gray-900':'',
              'cursor-pointer text-lg text-gray-500 flex')
             } data-key={SETTINGS_TAB_PRIVACY} onClick={handleSettingsTabClick}><UserCircleIcon className='w-6 h-6 mr-1 inline-flex'/>私隱設定</li>
            {/* <li className={classNames(
              settingsTab===SETTINGS_TAB_NOTIFICATION?'text-gray-900':'',
              'cursor-pointer text-lg text-gray-500 flex')
             } data-key={SETTINGS_TAB_NOTIFICATION} onClick={handleSettingsTabClick}><BellIcon className='w-6 h-6 mr-1 inline-flex'/>通知提示</li>
            <li className={classNames(
              settingsTab===SETTINGS_TAB_PAYMENT?'text-gray-900':'',
              'cursor-pointer text-lg text-gray-500 flex')
             } data-key={SETTINGS_TAB_PAYMENT} onClick={handleSettingsTabClick}><CreditCardIcon className='w-6 h-6 mr-1 inline-flex'/>付款方式</li> */}
          </ul>
        </aside>
        <div className='px-8 w-full'>
          {settingsFragment}
        </div>
      </div>
    </div>
  )
}

const EditProfileScreen = ({pageTitle, ...props}) => {
  const auth = useAuth()
  const profile = auth.profile
  const privateProfile = auth.privateProfile
  const user = auth.user

  const [isLoading, setIsLoading] = useState(false)

  console.log(profile)

  const { register, formState: { errors }, handleSubmit, trigger, getValues, control, watch, setValue, reset } = useForm({
    defaultValues: {
      firstName: profile.firstName,
      lastName: profile.lastName,
      displayName: profile.displayName,
      email: user.email,
      isAdviser: profile.isAdviser,
      isVerified: false,
      // profileImageFile: [],
      title: profile.title,
      tags: profile.tags.map(t=>{return{id:t, text:t}}),
      bio: profile.bio,
      category: profile.category,
      experience: profile.experience?profile.experience:[{category:'education', title: "", description: "", fromYear:'', toYear:''}],
      maskedItems: profile.maskedItems,
      phoneNumber: privateProfile.phoneNumber
    },
  });

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control,
      name: "experience",
    }
  );

    useEffect(()=>{
      // [TODO] implement better way to update profile and privateProfile
      // use effect to refresh privateprofile on update
      handleReset()
    }, [privateProfile])

  const handleReset = () => {
    reset(
      {
        firstName: profile.firstName,
        lastName: profile.lastName,
        displayName: profile.displayName,
        email: user.email,
        isAdviser: profile.isAdviser,
        isVerified: false,
        // profileImageFile: [],
        title: profile.title,
        tags: profile.tags.map(t=>{return{id:t, text:t}}),
        bio: profile.bio,
        category: profile.category,
        experience: profile.experience?profile.experience:[{category:'education', title: "", description: "", fromYear:'', toYear:''}],
        maskedItems: profile.maskedItems,
        phoneNumber: privateProfile.phoneNumber
      },
    )
  }

  const handleChangeProfile = () => {
    handleSubmit(data=>{
      if(!isLoading){
        setIsLoading(true)
        // if(profile.maskedItems.includes('displayName')){
        //   data.displayName = 'A SHARLO User'
        // }else{
        //   data.displayName = `${profile.firstName} ${profile.lastName}`
        // }
        
        auth.updateProfile(data,()=>{
          console.log('handleChangeProfile', data.phoneNumber)
          auth.updatePrivateProfile({phoneNumber:data.phoneNumber}, ()=>{
            toast('已更新資料')
            setIsLoading(false)
          })
        })


      }else{
        toast('請等等，我哋嘅intern直在幫你更新資料')
      } 

  })()

  }

  useEffect(()=>{
    handleReset()
  }, [user, profile])

  // useEffect(()=>{

  //   // document.querySelector('html').scrollTop = document.querySelector('#exp-input').scrollHeight
  // }, [fields])

  return(
    <>
      <h2>更改{pageTitle}</h2><br />
      <form className='mx-auto w-2/3' autocomplete="off">
        <ImageInput errors={errors.profileImageFile} image={watch('profileImageFile')?watch('profileImageFile')[0]:null} src={user.photoURL} register={()=>register("profileImageFile", { required: false })}>
        {/* 個人照片 */}
        </ImageInput>
        <div className="flex -mb-4 space-x-2">
        <input
            className={classNames(
              errors?.lastName?'border-red-300':'focus:outline-none focus:ring-teal-500 focus:border-teal-500 ',
              "appearance-none my-4 rounded-sm relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:z-10 sm:text-sm"
            )}
            name='lastName'
            placeholder="姓氏"
            {...register('lastName', {required: true})}
          />
          <input 
            className={classNames(
              errors?.firstName?'border-red-300':'focus:outline-none focus:ring-teal-500 focus:border-teal-500 ',
              "appearance-none my-4 rounded-sm relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:z-10 sm:text-sm"
            )}
            name='firstName'
            placeholder="名稱"
            {...register('firstName', {required: true})}
          />
        </div>
        <input
          className={classNames(
            errors?.email?'border-red-300':'focus:outline-none focus:ring-teal-500 focus:border-teal-500 ',
            "appearance-none my-4 rounded-sm relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:z-10 sm:text-sm"
          )}
          name='email'
          placeholder="電郵地址"
          {...register('email', {required: true})}
        />
        <input
            className={classNames(
              errors?.phoneNumber?'border-red-300':'focus:outline-none focus:ring-teal-500 focus:border-teal-500 ',
              "appearance-none my-4 rounded-sm relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:z-10 sm:text-sm"
            )}
            name='phoneNumber'
            placeholder="電話號瑪"
            type='tel' maxlength='8'
            {...register('phoneNumber', {required: false})}
          />
        <input
          className={classNames(
            errors?.password?'border-red-300':'focus:outline-none focus:ring-teal-500 focus:border-teal-500 ',
            "opacity-50 cursor-not-allowed appearance-none my-4 rounded-sm relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:z-10 sm:text-sm"
          )}
          type='password'
          name='password'
          placeholder="密碼" autocomplete="new-password"
          {...register('password', {required: false})}
        />
        <input
          className={classNames(
            errors?.confirmPassword?'border-red-300':'focus:outline-none focus:ring-teal-500 focus:border-teal-500 ',
            "opacity-50 cursor-not-allowed appearance-none my-4 rounded-sm relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:z-10 sm:text-sm"
          )}
          type='password'
          name='confirmPassword'
          placeholder="重覆密碼" autocomplete="new-password"
          {...register('confirmPassword', { required: false, validate: value => value === getValues('password') || "The passwords do not match" })}
        />

        <label><span>成為分享者</span>
          <input className="ml-2 h-4 w-4" type="checkbox" name="isAdviser" {...register("isAdviser", { required: false })} />
        </label>

        {watch('isAdviser')&&
        <div className="mt-2">
                    <fieldset>
            <legend>分享類別 (可選多項) </legend>
            <div className='mt-2 flex flex-wrap gap-6'>
              {
                SHARING_CATEGORIES.map(
                  (c,i) => <label key={i}><input className='mx-2' type="checkbox" value={c.value} {...register('category',  { required: getValues('isAdviser') })} />{c.name}</label>
                )
              }
            </div>
          </fieldset>
          {/* <label><span>主要分享類別</span>
            <select className='border mx-2 px-2 py-1 border-gray-300' {...register(`category`)}>
              <option value="study">升學分享</option>
              <option value="career">工作就業</option>
              <option value="business">商務諮詢</option>
              <option value="startup">初創企業</option>
              <option value="immigration">海外移民</option>
              <option value="lifestyle">生活風格</option>
            </select>
          </label> */}
        </div>
        }
        <input
          className={classNames(
            errors?.title?'border-red-300':'focus:outline-none focus:ring-teal-500 focus:border-teal-500 ',
            "appearance-none my-4 rounded-sm relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-400 text-gray-900 focus:z-10 sm:text-sm"
          )}
          name='title'
          placeholder="職業/身份: React.js Expert"
          {...register('title', {required: true})}
        />
        <label>
      {/* <span>關於自己</span> */}
      <textarea className={classNames(
        errors?.bio?'border-red-300':'focus:outline-none focus:ring-teal-500 focus:border-teal-500',
        "resize-none border rounded-xs w-full h-36 rounded-sm px-3 py-2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
      )}
      name="desciption" {...register("bio", { required: getValues('isAdviser') })} placeholder="簡介: A South African-born American entrepreneur and businessman who founded X.com in 1999 (which later became PayPal), SpaceX in 2002 and Tesla Motors in 2003." />
    </label>
    <TagInput placeholder='標籤: HKU STARTUP BBA' register={register} setValue={setValue} defaults={profile.tags.map(t=>{return{id:t, text:t}})} />
        <div id='exp-input'>
    {fields.map((item, index) => {
            return (
              <div key={item.id}>
                <div className="flex flex-row align-middle space-x-4">
                  <div className="block self-center">過往經驗{index+1}</div>
                  <select className='block border px-2 py-1 border-gray-300' {...register(`experience[${index}].category`)}>
                    <option value="education">學歷</option>
                    <option value="job">工作</option>
                    <option value="others">其他</option>
                  </select>
                  {index !== 0 && 
                    <Button className="self-center block w-6 h-6 text-red-600 opacity-70 hover:opacity-100" onClick={() => remove(index)}>
                      <MinusCircleIcon />
                    </Button>
                  }
                </div>
                <input
                  className={classNames(
                    errors?.experience?.[index]?.title?'border-red-300':'focus:outline-none focus:ring-teal-500 focus:border-teal-500 ',
                    "appearance-none my-4 rounded-sm relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:z-10 sm:text-sm"
                  )}
                  name={`experience[${index}].title`}
                  placeholder={EXPERIENCE_FIELD_PLACEHOLDER[watch(`experience[${index}].category`)].title}
                  defaultValue={`${item.title}`} // make sure to set up defaultValue
                  {...register(`experience[${index}].title`, {required: true})}
                />
                <input
                  className={classNames(
                    errors?.experience?.[index]?.description?'border-red-300':'focus:outline-none focus:ring-teal-500 focus:border-teal-500 ',
                    "appearance-none my-4 rounded-sm relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:z-10 sm:text-sm"
                )}
                  name={`experience[${index}].description`}
                  placeholder={EXPERIENCE_FIELD_PLACEHOLDER[watch(`experience[${index}].category`)].description}
                  defaultValue={`${item.description}`} // make sure to set up defaultValue
                  {...register(`experience[${index}].description`, {required: true})}
                />
                <div>
                  <YearPicker  register={()=>register(`experience[${index}].fromYear`, {required: true})}
                    placeholder="開始年份" defaultValue={`${item.fromYear}`} name={`experience[${index}].fromYear`}
                     className={classNames(
                      errors?.experience?.[index]?.fromYear?'border-red-300':'focus:outline-none focus:ring-teal-500 focus:border-teal-500 ',
                      " mb-4 rounded-sm relative inline-block w-1/2 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:z-10 sm:text-sm"
                    )}
                  />
                  <YearPicker  register={()=>register(`experience[${index}].toYear`, {required: true})}
                    placeholder="終結年份" defaultValue={`${item.toYear}`} name={`experience[${index}].toYear`}
                     className={classNames(
                      errors?.experience?.[index]?.toYear?'border-red-300':'focus:outline-none focus:ring-teal-500 focus:border-teal-500 ',
                      " mb-4 rounded-sm relative inline-block w-1/2 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:z-10 sm:text-sm"
                    )}
                  />
                  {/* <input
                    type="number"
                    className={classNames(
                      errors?.experience?.[index]?.fromYear?'border-red-300':'focus:outline-none focus:ring-teal-500 focus:border-teal-500 ',
                      "appearance-none mb-4 rounded-sm relative inline-block w-1/2 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:z-10 sm:text-sm"
                  )}
                    name={`experience[${index}].fromYear`}
                    placeholder="開始年份"
                    defaultValue={`${item.fromYear}`} // make sure to set up defaultValue
                    {...register(`experience[${index}].fromYear`, {required: true})}
                  />
                  <input
                    type="number"
                    className={classNames(
                      errors?.experience?.[index]?.toYear?'border-red-300':'focus:outline-none focus:ring-teal-500 focus:border-teal-500 ',
                      "appearance-none mb-4 rounded-sm relative inline-block w-1/2 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:z-10 sm:text-sm"
                  )}
                    name={`experience[${index}].toYear`}
                    placeholder="終結年份"
                    defaultValue={`${item.toYear}`} // make sure to set up defaultValue
                    {...register(`experience[${index}].toYear`, {validate: value => getValues(`experience[${index}].fromYear`)?true:value>=getValues(`experience[${index}].fromYear`) })}
                  /> */}
                </div>
              </div>
            );
          })}
          </div>
          <Button className="block h-8 w-8 text-gray-600 opacity-60 mx-auto hover:opacity-100" onClick={()=>{
            append({category:'education', title: "", description: "", fromYear:'', toYear:''});
            window.scrollTo({
              top: document.body.scrollHeight,
              behavior: "smooth"
            });
            }}><PlusCircleIcon /></Button>
         <div className='space-x-2 flex justify-end'>
          <Button className="border-2 border-teal-600 text-teal-600 text-sm p-2 rounded-md" onClick={handleReset}>還原</Button>
          <Button isLoading={isLoading} className="border-2 border-teal-600 bg-teal-600 text-white text-sm p-2 rounded-md" onClick={handleChangeProfile}>更改資料</Button>
         </div>
      </form>
    </>
  )
}

const EditPrivacyScreen = () => {
  const auth = useAuth()
  const profile = auth.profile
  const user = auth.user

  const [isLoading, setIsLoading] = useState(false)

  const handleReset = () => {
    maskedItems = {
      displayName: false,
      // title: false,
      profilePictureURL: false,
    }
  
    if(profile.maskedItems){
      for(const item of profile.maskedItems){
        maskedItems[item] = true
      }
    }
    reset(
      {
        displayName: maskedItems['displayName'],
        // title: maskedItems['title'],
        profilePictureURL: maskedItems['profilePictureURL'],
      },
    )
  }

  const handleChangeProfile = () => {
    handleSubmit(data=>{
      if(!isLoading){
        setIsLoading(true)
        const maskedItems = Object.keys(data).filter(k => data[k]===true)
        console.log('handleChangeProfile', maskedItems)
        console.log('handleChangeProfile', profile)
        auth.updateProfile({...profile, maskedItems:maskedItems},()=>{
          toast('已更新資料')
          setIsLoading(false)
        })
      }else{
        toast('請等等，我哋嘅intern直在幫你更新資料')
      } 

  })()

  }

  useEffect(()=>{
    // handleReset()
  }, [user, profile])

  let maskedItems = {
    displayName: false,
    // title: false,
    profilePictureURL: false,
  }


  if(profile.maskedItems){
    for(const item of profile.maskedItems){
      maskedItems[item] = true
    }
  }

  const { register, formState: { errors }, handleSubmit, trigger, getValues, control, watch, setValue, reset } = useForm({
    defaultValues: {
      displayName: maskedItems['displayName'],
      // title: maskedItems['title'],
      profilePictureURL: maskedItems['profilePictureURL'],
    },
  });

  return(
    <div>
      <h2>私隱設定</h2>
      <label><span>隱藏顯示名稱</span>
          <input className="ml-2 h-4 w-4" type="checkbox" name="displayName" {...register("displayName", { required: false })} />
        </label><br />
      {/* <label><span>隱藏職業/身份</span>
        <input className="ml-2 h-4 w-4" type="checkbox" name="title" {...register("title", { required: false })} />
      </label><br /> */}
      <label><span>隱藏個人圖片</span>
        <input className="ml-2 h-4 w-4" type="checkbox" name="profilePictureURL" {...register("profilePictureURL", { required: false })} />
      </label><br />
       <Button isLoading={isLoading} className="border-2 border-teal-600 bg-teal-600 text-white text-sm p-2 rounded-md" onClick={handleChangeProfile}>儲存選項</Button>
    </div>
  )
}

const EditNotificationScreen = () => {
  return(
    <div>🛎️ Work in Progress</div>
  )
}


const EditPaymentScreen = () => {
  const auth = useAuth()
  return(
    <div>
      <h2>付款資料</h2>
      <p>Balance： {auth.privateProfile.balance}</p>
      
      <p>Subscripted Till {stringfyFsTimestamp(auth.privateProfile.subscribedTill)}</p>
    </div>
  )
}

export default SettingsScreen