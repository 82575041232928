import { useAuth } from "../hooks/useAuth";
import logo from '../images/sharlo-logo.png';
import placeholder from '../images/placeholder-avatar.png'
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Button from "../components/Button";

import ImageInput from "../components/form/ImageInput";
import { MinusCircleIcon, QuestionMarkCircleIcon } from "@heroicons/react/solid";
import { PlusCircleIcon, CheckCircleIcon } from '@heroicons/react/solid'
import { useForm, useFieldArray } from "react-hook-form";
import classNames from "classnames";
import {
  useHistory,
  useLocation
} from "react-router-dom";
import TagInput from "../components/form/TagInput";
import YearPicker from "../components/form/YearPicker";
import { toast } from "react-toastify";
import { SHARING_CATEGORIES } from "../service/utility";

import ReactTooltip from 'react-tooltip';
import { isEmail } from "../service/validation";

const FIELDS = {
  1:['lastName', 'firstName', 'email', 'phoneNumber', 'password', 'confirmPassword', 'isAdviser', 'category'], 
  2:['profileImage', 'title', 'tags', 'bio'],
  3:['experience'],
}

const EXPERIENCE_FIELD_PLACEHOLDER = {
  'job': {title: '職位', description: '公司/機構'},
  'education': {title: '課程', description: '學校/機構'},
  'others': {title: '經驗簡述', description: '地點'},
}

const RegisterStepOne = ({register, getValues, errors, watch}) => {
  return(
    <>
    <h1 className="my-6">新用戶註冊</h1>
      <div className="">
        <ul className='flex justify-between my-6'>
          <li className="text-teal-700 font-bold">第一步 - 基本資料</li>
          <li className="text-gray-500">個人資料</li>
          <li className="text-gray-500">過往經驗</li>
        </ul>
        <div className='flex justify-end'>
          <QuestionMarkCircleIcon data-tip="你的個人資料有助我們配對分享者" className='w-4 h-4 opacity-50'></QuestionMarkCircleIcon>

        </div>
        <div className="flex -mb-4 space-x-2">
          <input
            className={classNames(
              errors?.lastName?'border-red-300':'focus:outline-none focus:ring-teal-500 focus:border-teal-500 ',
              "appearance-none my-4 rounded-sm relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:z-10 sm:text-sm"
            )}
            name='lastName'
            placeholder="姓氏"
            {...register('lastName', {required: '請輸入姓氏'})}
          />
          <input
            className={classNames(
              errors?.firstName?'border-red-300':'focus:outline-none focus:ring-teal-500 focus:border-teal-500 ',
              "appearance-none my-4 rounded-sm relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:z-10 sm:text-sm"
            )}
            name='firstName'
            placeholder="名稱"
            {...register('firstName', {required: '請輸入名稱'})}
          />

        </div>
        <input
          className={classNames(
            errors?.email?'border-red-300':'focus:outline-none focus:ring-teal-500 focus:border-teal-500 ',
            "appearance-none my-4 rounded-sm relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:z-10 sm:text-sm"
          )}
          name='email'
          placeholder="電郵地址"
          {...register('email', {required: '請輸入電郵地址', validate: value=>isEmail(value)||"請正確輸入電郵地址"})}
        />
          <input
            className={classNames(
              errors?.phoneNumber?'border-red-300':'focus:outline-none focus:ring-teal-500 focus:border-teal-500 ',
              "appearance-none my-4 rounded-sm relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:z-10 sm:text-sm"
            )}
            name='phoneNumber'
            placeholder="電話號瑪 (選填)"
            type='tel' maxlength='8'
            {...register('phoneNumber', {validate: value=> value=="" || value.length>=8 ||"請正確輸入電話號瑪"})}
          />
        <div className='flex justify-end'>
          <QuestionMarkCircleIcon data-tip="密碼需要8位以上" className='w-4 h-4 opacity-50'></QuestionMarkCircleIcon>
          {/* <ReactTooltip /> */}
        </div>
        <input
          className={classNames(
            errors?.password?'border-red-300':'focus:outline-none focus:ring-teal-500 focus:border-teal-500 ',
            "appearance-none my-4 rounded-sm relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:z-10 sm:text-sm"
          )}
          type='password'
          name='password'
          placeholder="密碼"
          {...register('password', {required: '請輸入密碼', validate: value => value.length>=8 || "密碼需要8位以上" })}
        />
        <input
          className={classNames(
            errors?.confirmPassword?'border-red-300':'focus:outline-none focus:ring-teal-500 focus:border-teal-500 ',
            "appearance-none my-4 rounded-sm relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:z-10 sm:text-sm"
          )}
          type='password'
          name='confirmPassword'
          placeholder="重覆密碼"
          {...register('confirmPassword', { required: '請再次輸入密碼', validate: value => value === getValues('password') || "請輸入相同的密碼" })}
        />
        <div className='flex justify-end'>
          <QuestionMarkCircleIcon data-tip="選擇成為分享者後便會被其他使用者搜尋到" className='w-4 h-4 opacity-50'></QuestionMarkCircleIcon>
          {/* <ReactTooltip /> */}
        </div>
        <label><span>成為分享者</span>
          <input className="ml-2 h-4 w-4" type="checkbox" name="isAdviser" {...register("isAdviser", { required: false })} />
        </label>

        {/* CATEGORIES = {
  'study': '升學分享', 
  'career': '工作就業', 
  'business': '商務諮詢', 
  'startup': '初創企業', 
  'immigration': '海外移民',
  'lifestyle': '生活風格'
} */}

        {watch('isAdviser')&&
        <div className="mt-2">

          <fieldset>
            <legend>分享類別 (可選多項) </legend>
            <div className='mt-2 flex flex-wrap gap-6'>
              {
                SHARING_CATEGORIES.map(
                  (c,i) => <label key={i}><input className='mx-2' type="checkbox" value={c.value} {...register('category',  { required: getValues('isAdviser') && '請只少選擇一項分享類別' })} />{c.name}</label>
                )
              }
            </div>
          </fieldset>

          {/* <label><span>主要分享類別</span>
            <select className='border mx-2 px-2 py-1 border-gray-300' {...register(`category`)}>
              <option value="study">升學分享</option>
              <option value="career">工作就業</option>
              <option value="business">商務諮詢</option>
              <option value="startup">初創企業</option>
              <option value="immigration">海外移民</option>
              <option value="lifestyle">生活風格</option>
            </select>
          </label> */}
        </div>
        }
      </div>
    </>
  )
}



const RegisterStepTwo = ({watch, register, errors, setValue, getValues}) => {
  return(
    <div className="">
    <ul className='flex justify-between my-6'>
      <li className="text-gray-600 font-bold"><CheckCircleIcon className="inline h-4 w-4"/>基本資料</li>
      <li className="text-teal-700 font-bold">第二步 - 個人資料</li>
      <li className="text-gray-500">過往經驗</li>
    </ul>
    <ImageInput placeholder={placeholder} errors={errors.profileImage} src='/placeholder/avatar.png' image={watch('profileImage')?watch('profileImage')[0]:null}
      register={()=>register("profileImage", 
        { validate:{
          required: files => files[0]||getValues('isAdviser') || '請上載圖片',
          fileSize: files => files[0]?.size < 2000000 || '圖片必須少於2MB',
        }
      })}>
    </ImageInput>
    <div className='flex justify-end'>
        {/* <QuestionMarkCircleIcon data-tip="hello world" className='w-4 h-4 opacity-50'></QuestionMarkCircleIcon> */}
        {/* <ReactTooltip /> */}
    </div>
    <input
          className={classNames(
            errors?.title?'border-red-300':'focus:outline-none focus:ring-teal-500 focus:border-teal-500 ',
            "appearance-none my-4 rounded-sm relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-400 text-gray-900 focus:z-10 sm:text-sm"
          )}
          name='title'
          placeholder="職業/身份: React.js Expert"
          {...register('title', {required: '請輸入職業/身份'})}
        />
        
    {/* <input
          className={classNames(
            errors?.tags?'border-red-300':'',
            "appearance-none focus:border-none focus:outline-none my-4 rounded-sm relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 sm:text-sm"
          )}
          name='tags'
          placeholder="標籤: HKU STARTUP BBA"
          {...register('tags', {required: true})}
    /> */}
    <div className='flex justify-end'>
      {/* <QuestionMarkCircleIcon data-tip="hello world" className='w-4 h-4 opacity-50'></QuestionMarkCircleIcon> */}
      {/* <ReactTooltip /> */}
    </div>
    <label>
      {/* <span>關於自己</span> */}
      <textarea className={classNames(
        getValues('isAdviser')?'my-4':'hidden',
        errors?.bio?'border-red-300':'focus:outline-none focus:ring-teal-500 focus:border-teal-500',
        "resize-none border rounded-xs w-full h-36 rounded-sm px-3 py-2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
      )}
      name="desciption" {...register("bio", { required: getValues('isAdviser') && '請輸入簡介' })} placeholder="A South African-born American entrepreneur and businessman who founded X.com in 1999 (which later became PayPal), SpaceX in 2002 and Tesla Motors in 2003." />
    </label>
    <div className='flex justify-end'>
      {/* <QuestionMarkCircleIcon data-tip="hello world" className='w-4 h-4 opacity-50'></QuestionMarkCircleIcon> */}
      {/* <ReactTooltip /> */}
    </div>
    <TagInput placeholder='標籤: HKU [Enter]' register={register} setValue={setValue} />
  </div>
  )
}

const RegisterStepThree = ({register, errors, fields, append, remove, watch, getValues, setValue}) => {
  useEffect(()=>{
    // window.scrollTo({
    //   top: document.querySelector('#exp-input').scrollHeight,
    //   behavior: "smooth"
    // });
    document.querySelector('#exp-input').scrollTop = document.querySelector('#exp-input').scrollHeight
  }, [fields])

  return(
    <div className="">
      <ul className='flex justify-between my-6'>
        <li className="text-gray-600 font-bold"><CheckCircleIcon className="inline h-4 w-4"/>基本資料</li>
        <li className="text-gray-600 font-bold"><CheckCircleIcon className="inline h-4 w-4"/>個人資料</li>
        <li  className="text-teal-700 font-bold">第三步 - 過往經驗</li>
      </ul>
      <div className='flex justify-end'>
        {/* <QuestionMarkCircleIcon data-tip="hello world" className='w-4 h-4 opacity-50'></QuestionMarkCircleIcon> */}
        {/* <ReactTooltip /> */}
      </div>
      <div id='exp-input' className="overflow-y-auto max-h-96">
        {fields.map((item, index) => {
            return (
              <div key={item.id}>
                <div className="flex flex-row align-middle space-x-4">
                  <div className="block self-center">過往經驗{index+1}</div>
                  <select className='block border px-2 py-1 border-gray-300' {...register(`experience[${index}].category`)}>
                    <option value="education">學歷</option>
                    <option value="job">工作</option>
                    <option value="others">其他</option>
                  </select>
                  {index !== 0 && 
                    <Button className="self-center block w-6 h-6 text-red-600 opacity-70 hover:opacity-100" onClick={() => remove(index)}>
                      <MinusCircleIcon />
                    </Button>
                  }
                </div>
                <input
                  className={classNames(
                    errors?.experience?.[index]?.title?'border-red-300':'focus:outline-none focus:ring-teal-500 focus:border-teal-500 ',
                    "appearance-none my-4 rounded-sm relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:z-10 sm:text-sm"
                  )}
                  name={`experience[${index}].title`}
                  placeholder={EXPERIENCE_FIELD_PLACEHOLDER[watch(`experience[${index}].category`)].title}
                  defaultValue={`${item.title}`} // make sure to set up defaultValue
                  {...register(`experience[${index}].title`, {required: '請輸入經驗'+EXPERIENCE_FIELD_PLACEHOLDER[watch(`experience[${index}].category`)].title})}
                />
                <input
                  className={classNames(
                    errors?.experience?.[index]?.description?'border-red-300':'focus:outline-none focus:ring-teal-500 focus:border-teal-500 ',
                    "appearance-none my-4 rounded-sm relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:z-10 sm:text-sm"
                )}
                  name={`experience[${index}].description`}
                  placeholder={EXPERIENCE_FIELD_PLACEHOLDER[watch(`experience[${index}].category`)].description}
                  defaultValue={`${item.description}`} // make sure to set up defaultValue
                  {...register(`experience[${index}].description`, {required: '請輸入經驗'+EXPERIENCE_FIELD_PLACEHOLDER[watch(`experience[${index}].category`)].description})}
                />
                <div>

                <YearPicker  register={()=>register(`experience[${index}].fromYear`, {required: '請輸入開始年份'})}
                    placeholder="開始年份" defaultValue={`${item.fromYear}`} name={`experience[${index}].fromYear`}
                     className={classNames(
                      errors?.experience?.[index]?.fromYear?'border-red-300':'focus:outline-none focus:ring-teal-500 focus:border-teal-500 ',
                      " mb-4 rounded-sm relative inline-block w-1/2 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:z-10 sm:text-sm"
                    )}
                  />
                  <YearPicker  register={()=>register(`experience[${index}].toYear`, {required: '請輸入終結年份'})}
                    placeholder="終結年份" defaultValue={`${item.toYear}`} name={`experience[${index}].toYear`}
                     className={classNames(
                      errors?.experience?.[index]?.toYear?'border-red-300':'focus:outline-none focus:ring-teal-500 focus:border-teal-500 ',
                      " mb-4 rounded-sm relative inline-block w-1/2 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:z-10 sm:text-sm"
                    )}
                  />

                  {/* <input
                    type="number"
                    className={classNames(
                      errors?.experience?.[index]?.fromYear?'border-red-300':'focus:outline-none focus:ring-teal-500 focus:border-teal-500 ',
                      "appearance-none mb-4 rounded-sm relative inline-block w-1/2 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:z-10 sm:text-sm"
                  )}
                    name={`experience[${index}].fromYear`}
                    placeholder="開始年份"
                    defaultValue={`${item.fromYear}`} // make sure to set up defaultValue
                    {...register(`experience[${index}].fromYear`, {required: true})}
                  />
                  <input
                    type="number"
                    className={classNames(
                      errors?.experience?.[index]?.toYear?'border-red-300':'focus:outline-none focus:ring-teal-500 focus:border-teal-500 ',
                      "appearance-none mb-4 rounded-sm relative inline-block w-1/2 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:z-10 sm:text-sm"
                  )}
                    name={`experience[${index}].toYear`}
                    placeholder="終結年份"
                    defaultValue={`${item.toYear}`} // make sure to set up defaultValue
                    {...register(`experience[${index}].toYear`, {validate: value => getValues(`experience[${index}].fromYear`)?true:value>=getValues(`experience[${index}].fromYear`) })}
                  /> */}
                </div>
              </div>
            );
          })}
        </div>

      {/* <ExperienceInput inputList={inputList} handleInputChange={handleInputChange} handleAddClick={handleAddClick} handleRemoveClick={handleRemoveClick} /> */}
      <Button className="block h-8 w-8 text-gray-600 opacity-60 mx-auto hover:opacity-100" onClick={()=>{append({category:'education', title: "", description: "", fromYear:'', toYear:''});}}><PlusCircleIcon /></Button>
    </div>
  )
}

export default function RegisterScreen() {
  let history = useHistory();
  let location = useLocation();
  let auth = useAuth();

  const { register, formState: { errors }, handleSubmit, trigger, getValues, control, watch, setValue } = useForm({
    defaultValues: {
      tags: [],
      experience: [{category:'education', title: "", description: "", fromYear:'', toYear:''}]
    },
  });

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control,
      name: "experience",
    }
  );

  const [step, setStep] = useState(1)
  const [isLoading, setIsLoading] = useState(false)


  // useEffect(()=>{
  //   return ()=>{setIsLoading(false)}
  // })

  const handleRegister = (data) => {
    console.log('isLoading', isLoading)
    // console.log(data)
    // toast.warning('pasued registeration')
    // return
    if(isLoading){
      toast.warning('請等等，我哋嘅intern正在輸入你嘅資料入去database')
      return
    }
    setIsLoading(true)
    const userProfile = {
      firstName: data.firstName,
      lastName: data.lastName,
      displayName: `${data.firstName} ${data.lastName}`,
      phoneNumber: data.phoneNumber,
      isAdviser: data.isAdviser,
      isVerified: false,
      profileImageFile: data.profileImage[0],
      title: data.title,
      tags: data.tags,
      bio: data.bio,
      category: data.category || null,
      experience: data.experience
    }

    auth.signup(() => {
      history.replace({ pathname: "/" });
      setIsLoading(false)
    }, data.email, data.password, userProfile)
      .catch(err => {toast.error(err);setIsLoading(false)})
    };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center sm:px-6 lg:px-8 bg-gray-100">
      <div className="max-w-md w-screen px-14 pt-8 pb-16 shadow-md bg-white">
        {step===1&&<img src={logo} alt='SHARLO Logo'  className="h-12"/>}
        
        {step===1&&<RegisterStepOne register={register} getValues={getValues} errors={errors} watch={watch}/>}
        {step===2&&<RegisterStepTwo register={register} watch={watch} errors={errors} setValue={setValue} getValues={getValues}/>}
        {step===3&&<RegisterStepThree register={register} control={control} errors={errors}  fields={fields} append={append} remove={remove} watch={watch} setValue={setValue} getValues={getValues}/>}

        <div className="flex flex-row justify-between mt-6">
          {step === 1 && <Link to="/login"><button className="py-2 px-4 border border-transparent text-sm font-medium rounded-md text-teal-600 text-left bg-white">返回</button></Link>}
          {step !== 1 && <Button className="py-2 px-4 border border-transparent text-sm font-medium rounded-md text-teal-600 text-left bg-white" 
          onClick={() => { setStep(step - 1) }}>返回</Button>}

          {step === 3 && <Button isLoading={isLoading}
          className="py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white text-center bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500" 
            onClick={async() => {
              const result = await trigger();
              if(result) {
                handleSubmit(handleRegister)()
              } else {
                toast.error('請正確輸入表單')
              }
            }
          }>註冊</Button>}
          {step !== 3 && <Button className="py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white text-center bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500" 
          onClick={async() => {
            const result = await trigger(FIELDS[step]);
            if(result){
              setStep(step+1)
            }else{
              console.log(errors)
              FIELDS[step].forEach((field)=>{if(errors[field])toast.error(errors[field].message || '請正確輸入表單')})
              // toast.error(result)
            }}
            }>下一步</Button>}
        </div>
        <div className="flex justify-start space-x-4 text-gray-500 mt-6 -mb-12 text-sm">
          <a className="" href="https://sharlohk.com/tos" target="_blank" rel="noopener noreferrer">條款</a>
          <a className="" href="https://sharlohk.com/privacy" target="_blank" rel="noopener noreferrer">私隱權政策</a>
        </div>
      </div>
      <ReactTooltip />
    </div>
  );
}