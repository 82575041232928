import { useState } from "react"
import { fsapi } from "../../service/api"
import Button from "../Button"
import ProfileBasicInfo from "../ProfileBasicInfo"

const ConfirmBookingStatusModal = ({auth, booking, status}) => {
  const [isLoading, setIsLoading] = useState(false)
  const overlay = auth.overlay

  const handleStatusChange = async () => {
    if(isLoading)
      return
    setIsLoading(true);
    const createdBooking = fsapi.changeBookingStatus(booking.bookingId, status, auth.profile)
    setIsLoading(false);
    if(createdBooking){
      console.log('changed booking status', createdBooking)
    }
    overlay.toggleOverlay()
  }

  return(
    <div className="space-y-6 min-w-96 px-6">
      {status==='accepted'&&<h2>接受分享預約</h2>}
      {status==='cancelled'&&<h2>取消分享預約</h2>}
      <ProfileBasicInfo user={booking} />
      <Button 
        isLoading={isLoading}
        onClick={handleStatusChange}
        className="float-right py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white  bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500">確認</Button>
    </div>
  )
}

export default ConfirmBookingStatusModal