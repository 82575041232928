import { useForm, useFieldArray } from "react-hook-form"
import { useEffect, useState } from "react"
import { useAuth } from "../hooks/useAuth"
import { classNames } from "../service/utility"
import {
  useHistory,
} from "react-router-dom";
import {UserCircleIcon, BellIcon, CreditCardIcon, MinusCircleIcon, PlusCircleIcon} from '@heroicons/react/solid'

import logo from '../images/sharlo-logo.png';

import ImageInput from "../components/form/ImageInput"
import TagInput from "../components/form/TagInput"

import Button from "../components/Button"

import { toast } from "react-toastify"
import YearPicker from "../components/form/YearPicker"
import firebase from "firebase";

const VerificationScreen = (props) => {
  const auth = useAuth()
  const history = useHistory()
  const [active, setActive] = useState(true)
  const [count, setCount] = useState(0)

  // useEffect(()=>{
  //   const unsubscribe = firebase.auth().onIdTokenChanged(user=>{
  //     if(user.emailVerified){
  //       user.reload()
  //       history.push('/')
  //     }
  //   })
  //   return ()=>{unsubscribe()}
  // }, [auth])

  useEffect(()=>{
    if(auth.user&&!auth.user.emailVerified){
      // auth.user.sendEmailVerification().then(()=>{toast('verification email sent')}).catch(err=>console.log(err))
    }else if(auth.user.emailVerified){
      history.push('/')
    }

    const listenUserRecord = setInterval(()=>{
      auth.user.reload();
      if(auth.user.emailVerified){
        history.push('/')
        clearInterval(listenUserRecord)
      }
      
      if(count>=24){ //24 cycles * 5 sec = 2 mins
        clearInterval(listenUserRecord)
      }else{
        setCount(count+1)
      }
      // console.log('user emailVerified - ', auth.user.emailVerified)
    }, 5000)

    return ()=>{listenUserRecord && clearInterval(listenUserRecord)}

  }, [])

  useEffect(()=>{
    if(active) return
    const listenActive = setTimeout(()=>{
      setActive(true)
    }, 5000)
    return ()=>{listenActive && clearTimeout(listenActive)}
  }, [active])

  const handleEmailResend = () => {
    if(!active){
      toast.warning('請等一陣再嘗試')
      return false
    }

    auth.user.reload();
    if(auth.user.emailVerified){
      history.push('/')
    }else{
      setActive(false)
      auth.user.sendEmailVerification({url:`https://${window.location.host}`}).then(()=>{toast('已重新發送認證郵件')}).catch(err=>{toast('未能發送認證郵件，請聯絡客戶服務主任');})
    }
  }

  return(
    <div className="min-h-screen flex flex-row items-center justify-center sm:px-6 lg:px-8 bg-gray-100">
      <div className="flex-auto max-w-md w-screen self-center px-16 py-16 shadow-md bg-white">
        <img src={logo} alt='SHARLO Logo'  className="h-12"/>
        <div>
          <h2 className="mt-6 text-left text-xl font-bold text-gray-500">電郵地址認證</h2>
          <p className="text-gray-400">請到嘅打開你嘅電郵郵箱({auth.user.email})，然後按下我哋發送比你嘅鏈結</p>
        </div>
        <form className="mt-8 space-y-6">
          <div className="flex items-center justify-between">
              <Button onClick={auth.signout} className="font-medium text-red-400 hover:text-red-600">取消</Button>
              <Button href="#" onClick={handleEmailResend} className="font-medium text-teal-600 hover:text-teal-700">重新發送</Button>
          </div>
        </form>
      </div>
    </div>
  )
}


export default VerificationScreen