import { useAuth } from "../hooks/useAuth";
import logo from '../images/sharlo-logo.png';
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import {
  useHistory,
  useLocation
} from "react-router-dom";
import useInput from "../hooks/useInput";
import Button from "../components/Button";
import { useState } from "react";
// import background from '../../public/images/app-auth-bg.png'

const REGEX_EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const VALIDATION_MSG = {
  email: {
    'required': '請輸入EMAIL',
    'pattern': '請輸入正確EMAIL格式',
  },
  password: {
    'required': '請輸入密碼',
  }
}

export default function LoginScreen() {
  const auth = useAuth();
  const history = useHistory();
  const location = useLocation();

  // const email = useInput('');
  // const password = useInput('');
  const { register, formState: { errors }, handleSubmit } = useForm();

  const [isLoggingIn, setIsLogginIn] = useState(false);

  const { from } = location.state || { from: { pathname: "/" } };

  const handleLogin = (data) => {
    // alert(JSON.stringify(data))
    // return true
    if (isLoggingIn) {
      return
    }
    setIsLogginIn(true)
    auth.signin(() => {
      history.replace(from)
    }, data.email, data.password).catch(err => { setIsLogginIn(false); alert(err) })
  };

  return (
    <div className="min-h-screen flex flex-row items-center justify-center sm:px-6 lg:px-8 bg-gray-100">
      <div className="flex-auto max-w-md w-screen self-center px-16 py-16 shadow-md bg-white">
        <img src={logo} alt='SHARLO Logo'  className="h-12"/>
        <div>
          <h2 className="mt-6 text-left text-xl font-bold text-gray-500">立即登入</h2>
          <span className="text-gray-400">讓經驗引領生活</span>
        </div>
        <form className="mt-8 space-y-6" onSubmit={handleSubmit(handleLogin)}>
          {/* <input type="hidden" name="remember" defaultValue="true" /> */}
          <div className="rounded-md -space-y-px">
            <div className="flex flex-col">
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              {errors.email?<span className="self-end text-red-500 text-sm font-bold">{VALIDATION_MSG['email'][errors.email.type]}</span>:''}
              <input
              className={classNames(
                errors.email?'border-red-300':'focus:outline-none focus:ring-teal-500 focus:border-teal-500 ',
                "appearance-none my-4 rounded-sm relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:z-10 sm:text-sm"
              )}
                type="text" name="email" autoComplete="email"
                required {...register("email", {required: true, pattern: REGEX_EMAIL})}placeholder="elon.musk@sharlohk.com" />
                          </div>
            <div className="flex flex-col">
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              {errors.email?<span className="self-end text-red-500 text-sm font-bold">{VALIDATION_MSG['password'][errors.email.type]}</span>:''}
              <input
                name="password"
                type="password"
                autoComplete="current-password"
                className={classNames(
                  errors.password?'border-red-300':'focus:outline-none focus:ring-teal-500 focus:border-teal-500 ',
                  "appearance-none my-4 rounded-sm relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:z-10 sm:text-sm"
                )}
                {...register("password", {required: true})} placeholder="**********"
              />
  
            </div>
          </div>

          <div className="flex items-center justify-end">
            <div className="text-sm">
              <a href="/reset-password" className="font-medium text-teal-600 hover:text-teal-500">
                忘記密碼?
              </a>
            </div>
          </div>

          <div className="flex justify-end space-x-4">
            <Link to="/register"><Button
              className="py-2 px-4 border border-transparent text-sm font-medium rounded-md text-teal-600 hover:text-white bg-white-600 hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500">註冊</Button></Link>
            <Button
              isLoading={isLoggingIn}
              className="py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white text-center bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
              onClick={handleSubmit(handleLogin)} >登入</Button>
          </div>
        </form>
      </div>
    </div>
  )
}