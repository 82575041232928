import { Link } from "react-router-dom"
import CircularImage from "./CircularImage"
import placeholer from '../images/placeholder-avatar.png'

const ProfileBasicInfo = (props) => {
  const user = props.user
  return(
    <div className="flex flex-row">
      <Link to={`/user/${user.aid || user.uid}`}>
        <CircularImage size="w-20 h-20" alt={user.displayName} src={user.profilePictureURL||placeholer} />
      </Link>
      <div className="space-y-2 ml-4 self-center">
        <h3 className="text-xl"><Link to={`/user/${user.aid || user.uid}`}>{user.displayName}</Link></h3>
        <span className="">{user.title}</span>
      </div>
    </div>
  )
}

export default ProfileBasicInfo