import { Link } from "react-router-dom"
import classNames from "classnames"

const Tag = ({highlight,...props}) => {
  return(
    <Link to={`/search?query=${props.children}`}>
      <div className={classNames(
        highlight?'bg-teal-600 text-white hover:bg-teal-700':' bg-teal-100 text-teal-700 hover:bg-teal-200',
        "mr-2 mt-2 inline-block text-sm font-medium px-3 py-1 rounded-full cursor-pointer "
      )}>{props.children}</div>
    </Link>
      )
}

export default Tag