import { useAuth } from '../hooks/useAuth';
import ProfileListButtons from '../components/ProfileListButtons';
import ProfileListItem from '../components/ProfileListItem';
import { useEffect, useState } from 'react';

import SearchBar from '../components/SearchBar';

import Button from '../components/Button';
import TextInput from '../components/TextInput';
import Tag from '../components/Tag';
import { Link } from 'react-router-dom';

import { db } from '../service/api';
import CategorySection from '../components/CategorySection';
import CircularImage from '../components/CircularImage';
import ReactTooltip from 'react-tooltip';

export default function HomeScreen(props) {

  const [profileList, setProfileList] = useState([])

  let auth = useAuth();
  let profiles = profileList.slice(0, 3).filter(user=>user.uid!==auth.user.uid).map((user) => 
    <ProfileListItem key={user.uid} user={user} actionButtons={<ProfileListButtons user={user} updateBookingList={()=>{alert('suppose re-fetch booking list')}}/>} />
  )

  useEffect(()=>{
    (async ()=>{
      const list = []
      const result = await db.collection("userProfile").where("isAdviser", "==", true).orderBy('popularity', 'desc').limit(4).get()
      result.forEach((doc)=>{
        list.push(doc.data())
      })
      console.log('HomeScreen', list)
      setProfileList(list)
    })()
  }, [])

  return (
    <div className="w-full -mt-8">
      <div className="bg-white px-6 md:px-20 pb-8">
        <div className="flex flex-wrap md:flex-nowrap max-w-screen-lg mx-auto md:justify-center items-center">
          <div className=''>
            <img alt="social media on phone" src='/images/video-call.gif' className="h-48 md:h-96 md:m-16"/>
          </div> 

          <div className=''>
          <h2 className="text-5xl tracking-widest">面對挑戰<br />我們再不孤單</h2>
          <div className="my-6 md:space-x-2"><Tag>HKU</Tag><Tag>COMPSCI</Tag><Tag>STARTUP</Tag></div>
            <SearchBar searchButton={true}/>
          </div>

        </div>
      </div>

     
      <div className="max-w-screen-lg mx-auto my-8 space-y-6 px-2">   
        <h2>分享類型</h2>
        <CategorySection />
      </div>

      <img src='/images/home-launch-promotion.png' className='w-full max-w-screen-lg mx-auto px-2 rounded-md'/>

      <div className='max-w-screen-lg mx-auto my-8 space-y-6 px-2'>
        <h2>熱門分享</h2>
        <div className='grid grid-cols-1 md:grid-cols-2 grid-flow-row gap-4 place-content-evenly'>
          <FeaturedCard title='大學生活' tags={['HSBC', 'RETAIL', '銀行']}/>
          <FeaturedCard title='移民生活' tags={['BOOTCAMP', 'COMPSCI', 'UI']} />
          <FeaturedCard title='Management Trainee' tags={['PDGE', '教師', 'TEACHER']} />
          <FeaturedCard title='醫療學科' tags={['GCSE', 'IELTS', 'UK', 'USA']} />
        </div>
      </div>

      <div className='max-w-screen-lg mx-auto my-8 space-y-6 px-2'>
        <h2>精選分享者</h2>
        <ProfileCard />
      </div>

      <img src='/images/home-cta-banner.png' className='w-full max-w-screen-lg mx-auto px-2 rounded-md'/>

      {/* <div className='max-w-screen-lg mx-auto px-4 md:px-16 py-8 flex items-center bg-white px-2'>
      <img className='hidden rounded-md w-64 mx-8' src='/images/chat-over-coffee-scaled.webp' alt='chat over warm coffee' />
        <div className=''>
         
          <h2 className='text-2xl my-2'>SHARLO 網上經驗分享平台</h2>
          <p>我們深信每人的經驗和意見都總有價值，因此我們把諮詢行業平民化。<br />讓你在工餘時間能夠向有需要人仕提供意見，同時賺取額外收入。</p>
          <Button
          className="my-4 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white text-center bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500" 
          ><a href='https://sharlo.hk/adviser-guide/' target='_blank' rel='nofollow noreferrer noopener'>成為分享者</a></Button>
        </div>
      </div> */}
      <ReactTooltip />
    </div>
    
  )
}

const FeaturedCard = ({title ,tags=[]}) => {

  const [featuredProfile, setFeaturedProfile] = useState([])

  useEffect(()=>{
    (async ()=>{
      let list = []
      // const result = await db.collection("userProfile").where("tags", "array-contains", tags[0]).orderBy('popularity', 'desc').limit(3).get()
      const result = await db.collection("userProfile").where("isAdviser", "==", true).orderBy('popularity', 'desc').limit(4).get()
      result.forEach((doc)=>{
          list.push(doc.data())
        })
      // alert('FeaturedCard', list)
      // setFeaturedProfile(shuffle(list).slice(0, 4))
      if(list.length<4){
        list.push(list[0])
      }
      setFeaturedProfile(list)
    })()
  }, [])

  
  return (
    <div className='bg-white rounded-md'>
      <div className='flex flex-wrap gap-4 my-4 px-6 items-center'>

        <div className="flex w-full items-center justify-between">
          <span className='font-semibold text-xl block'>{title}</span>
          <Button
            className='my-4 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white text-center bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500'
            >進入專題</Button>
        </div>
        
        <div className='flex gap-2 justify-between w-full md:mx-4'>
          {featuredProfile.map(profile=>
          <Link to={`/user/${profile.uid}`} className='w-1/4' data-tip={profile.title}>
            <CircularImage size="md:w-20 md:h-20" alt={profile.displayName} src={profile.profilePictureURL} />
          </Link>)}
          
        </div>

        <div>
          {tags.map(t=><Tag>{t}</Tag>)}
        </div>

      </div>
    </div>
  )
}

const ProfileCard = ({title ,tags=[]}) => {

  const [featuredProfile, setFeaturedProfile] = useState([])

  useEffect(()=>{
    (async ()=>{
      let list = []
      // const result = await db.collection("userProfile").where("tags", "array-contains", tags[0]).orderBy('popularity', 'desc').limit(3).get()
      const result = await db.collection("userProfile").where("isAdviser", "==", true).orderBy('popularity', 'desc').limit(8).get()
      result.forEach((doc)=>{
          list.push(doc.data())
        })
      // alert('FeaturedCard', list)
      // setFeaturedProfile(shuffle(list).slice(0, 4))
      if(list.length<8){
        for(let i=list.length; i<8; i++){
          list.push(list[0])
        }
      }
      setFeaturedProfile(shuffle(list))
    })()
  }, [])

  
  return (
    <div className='grid grid-cols-4 gap-4'>
      {featuredProfile.map(profile=>
      <div className='bg-white rounded-md p-4 text-center space-y-2'>
        <Link to={`/user/${profile.uid}`}>
          <CircularImage size="md:w-20 md:h-20 mx-auto" alt={profile.displayName} src={profile.profilePictureURL} />
        </Link>
        <div className='font-bold'>{profile.title}</div>
        {/* <div>{profile.displayName}</div> */}
        <div className=''>{profile.experience[0].description}</div>
        <div>{profile.tags.slice(0,3).map(t=><Tag>{t}</Tag>)}</div>
      </div>
      )}

    </div>
  )
}

/**
 * Shuffles array in place.
 * @param {Array} a items An array containing the items.
 */
 function shuffle (arr) {
  var j, x, index;
  for (index = arr.length - 1; index > 0; index--) {
      j = Math.floor(Math.random() * (index + 1));
      x = arr[index];
      arr[index] = arr[j];
      arr[j] = x;
  }
  return arr;
}
