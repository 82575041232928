import classNames from "classnames"
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import Button from "./Button";
const SearchBar = ({searchButton, ...props}) => {
  const history = useHistory()
  const inputRef = useRef()
  const { register, formState: { errors }, reset, handleSubmit, trigger, getValues, control, watch, setValue } = useForm({
    defaultValues: {
      qs: ''
    },
  });

  // useEffect(() => {
  //   const currentInput = inputRef.current
  //   const listener = event => {
  //     if (event.code === "Enter" || event.code === "NumpadEnter") {
  //       event.preventDefault();
  //       handleSubmit(({qs, ...data})=>{
  //         if(qs.trim()){
  //           history.push(`/search?query=${qs.trim()}`)
  //           reset({'qs':''})
  //         } 
  //       })()
  //     }
  //   };
  //   if(currentInput){
  //     currentInput.addEventListener("keydown", listener);
  //   }
    
  //   return () => {
  //     currentInput.removeEventListener("keydown", listener);
  //   };
  // }, []);

  const handleKeyPress = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        handleSubmit(({qs, ...data})=>{
          if(qs.trim()){
            history.push(`/search?query=${qs.trim()}`)
            reset({'qs':''})
          } 
        })()
      }
  }

  const handleSearchButton = () => {
    handleSubmit(({qs, ...data})=>{
      if(qs.trim()){
        history.push(`/search?query=${qs.trim()}`)
        reset({'qs':''})
      } 
    })()
  }

  return(
    <form className='flex'>
      <input {...register('qs', {required: true})} onKeyPress={handleKeyPress}
      type="text" placeholder='試下搵 "HKU" ' className={classNames(
        props.className?props.className:'h-10 inlie-block rounded-l-md border-2 border-solid border-gray px-4 py-2 w-full md:w-96'
      )}
      />
      {searchButton&&<Button onClick={handleSearchButton} className="inlie-block h-10 py-2 px-4 border border-transparent text-sm font-medium rounded-r-md text-white hover:bg-teal-700 bg-teal-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 -ml-1 flex-shrink-0">搜尋</Button>}
    </form>
    
  )
}

export default SearchBar