const YearPicker = ({defaultValue, className, register, placeholder, name}) => {
    const CURRENT = 9999
    const year = (new Date()).getFullYear();
    const years = [CURRENT].concat(Array.from(new Array(100),(val, index) => year-index));
    return(
      <select name={name} defaultValue={defaultValue} className={className} {...register()}>
        <option hidden value="">{placeholder}</option>
        {
          years.map((year, index) => {
            return year!==CURRENT?
              <option key={`year${index}`} value={year}>{year}</option>
              :
              <option key={`year${index}`} value={year}>現在</option>
          })
        }
      </select>
    )
  }

  export default YearPicker