import classNames from "classnames"

const ImageInput = (props) => {
  console.log('ImageInput',props.image)
  return (
    <>
      <label>{props.children}</label>
      <div class="flex flex-wrap justify-center py-6">
        <div class="">
          <label for="image-upload" className="block relative group cursor-pointer w-full h-full">
            <svg xmlns="http://www.w3.org/2000/svg" className="absolute z-50 w-6 h-6 opacity-30 group-hover:opacity-80 bottom-0 right-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
            </svg>
            <img className={classNames(
              props.errors?'border-red-300':'border-gray-200',
              'block object-cover w-32 h-32 mr-2 rounded-full border' 
            )} src={(props.image) ? URL.createObjectURL(props.image) : props.src} alt="Profile"/>
          </label>
        </div>
        <input type="file" accept="image/*"  id="image-upload" className="hidden" {...props.register()} />
      </div>
    </>
  )
}

export default ImageInput