import classNames from "classnames"

const loadingText = <>
<svg class="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
  <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
</svg>
</>

const Button = (props) => {
  // const BUTTON_COLOUR = {
  //   primary: 'teal-600',
  //   secondary: 'blue-600',
  //   disabled: '',
  //   danger: 'red-600',
  // }
  
  // const BUTTON_SIZE = {
  //   xs: 'text-xs',
  //   sm: 'text-sm',
  //   base: 'text-base'
  // }
  
  // const BUTTON_TYPE = {
  //   text: 'bg-transparent',
  //   fill: classNames(BUTTON_COLOUR[props.colour]),
  //   outline: 'outline-true',
  // }
  
  return(<button className={classNames(
    props.isLoading?'cursor-wait opacity-50':'',props.className
    )} type="button" onClick={props.onClick}>{(props.isLoading)?loadingText:props.children}</button>)
}

export default Button