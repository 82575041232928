import { useAuth } from "../hooks/useAuth"
import { getBookingMessage } from "../service/utility"
import { CalendarIcon, MinusCircleIcon } from "@heroicons/react/solid"
import classNames from "classnames"
const NotificationScreen = (props) => {
  const auth = useAuth()
  const handleSeenNotification = (n) => {
    if(n.is_read){
      return true
    }else{
      auth.setNotificationRead(n.id)
    }
  }
  const handleAllSeenNotification = () => {
    auth.setAllNotificationRead()
  }
  return(
    <div className="max-w-screen-lg flex flex-wrap w-full mx-6">
      <div className='flex justify-between w-full'>
        <h1>通知</h1>
        <span className='float-right cursor-pointer flex' onClick={handleAllSeenNotification}><MinusCircleIcon className='h-6 w-6 inline-flex mr-1'/>標記所有為已閱讀</span>
      </div>
      <div className="w-full max-h-full overflow-y-auto my-4">
        <ul className='space-y-4'>
          {auth.notifications.map(n=>
          <li key={n.id}  className={classNames(
            (n.is_read)?"text-gray-300":"cursor-pointer",
            'bg-white rounded-md py-4 px-6 space-y-4'
            )} onClick={()=>{handleSeenNotification(n)}}>
              <div className='flex text-gray-500 '><CalendarIcon className='h-6 w-6 inline-flex mr-2'/>{n.type.indexOf('BOOKING')>=0?'預約更新':'訊息提示'}</div>
              <div className='flex flex-row flex-wrap justify-end gap-6'>
                <p className='flex-auto'>{getBookingMessage(n, auth.profile)}</p>
                <span className='text-gray-400'>{new Date(n.created_at.seconds * 1000).toLocaleString('en-US', { timeZone: 'Asia/Hong_Kong' })}</span>
              </div>
                        </li>)
          }
        </ul>
      </div>
    </div>
  )
}

export default NotificationScreen