import classNames from "classnames"

export default function ChatMessage(props){
  let msg = props.message
  let bookingProfileUid = props.bookingProfileUid
  console.log(`message uid: ${msg.uid} bookingProfileUid : ${bookingProfileUid}`)
  return(
    <div className={classNames(
      (msg.uid === bookingProfileUid)?'self-start':'chat-message self-end',
      'bg-gray-100 py-2 px-4 rounded-md'
    )}>
      <span>{msg.content}</span>
    </div>
  )
}