import ProfileListItem from "../components/ProfileListItem"
import ProfileListButtons from "../components/ProfileListButtons"
import { Link } from "react-router-dom";
import 'instantsearch.css/themes/reset.css';
import { useLocation } from "react-router";

import { useAuth } from "../hooks/useAuth";
import { useEffect, useState } from "react";

import algoliasearch from 'algoliasearch/lite';
import { SearchBox, InstantSearch,Configure, Hits, CurrentRefinements, RefinementList, Pagination, ToggleRefinement } from 'react-instantsearch-dom';

import { connectSearchBox } from "react-instantsearch-core";
import CategorySection from "../components/CategorySection";
import { db } from "../service/api";
import SimpleProfileItem from "../components/SimpleProfileItem";

import CircularImage from "../components/CircularImage";
import Tag from '../components/Tag'
import { Highlight } from "react-instantsearch-dom";

import { connectHighlight } from "react-instantsearch-core";
import { BadgeCheckIcon } from "@heroicons/react/solid";

import ReactTooltip from "react-tooltip";

const isTagHitHighlighted = (hit) => {
  for(const h of hit){
    if(h.isHighlighted) return true
  }
  return false
}

const TagHighlight = ({ highlight, attribute, hit }) => {
  const parsedHit = highlight({
    highlightProperty: '_highlightResult',
    attribute,
    hit,
  });

  console.log(parsedHit)

  return (
    <>
      {parsedHit.map(
        (tag, index) => {
          return(
            <Tag highlight={isTagHitHighlighted(tag)} key={index}>{
              tag.map((part, index) => 
              part.isHighlighted ? (
                part.value
              ) : (
                part.value
              )
            ).join('')
            }</Tag>
          )
        }
      )}
    </>
  );
};

const TagHighlighted = connectHighlight(TagHighlight);

function truncate(str, maxLength) {
  return str.substr(0, maxLength)
}


function Hit(props){
  const user = props.hit
  const MAIN_TEXT_LENGTH = 1000
  return(
    <Link to={`/user/${props.hit.uid}`}>
      <div className="flex p-8 rounded-md hover:bg-gray-50">
        
        <Link to={`/user/${user.aid || user.uid}`}>
          <CircularImage size="w-20 h-20" alt={user.displayName} src={user.profilePictureURL} />
        </Link>
        <div className="space-y-2 ml-6 w-full">
          {props.hit.isVerified&&
            <>
              <BadgeCheckIcon className='w-6 h-6 block float-right opacity-75' data-tip="SHARLO已認證該用戶身份"/>
              <ReactTooltip />
            </>
          }
          <h3 className="inline-block text-xl"><Link to={`/user/${user.aid || user.uid}`}>{user.displayName}</Link></h3><span className="ml-4">{user.title}</span>
          <div className='h-12 overflow-hidden flex items-center truncated'>{user.bio}</div>
          {/* {!props.hideTag&&<ul>{user.tags.map(t => <Tag>{t}</Tag>)}</ul>} */}
          <TagHighlighted hit={props.hit} attribute="tags" />
        </div>
      </div>
    </Link>
  )
}

// const Hits = ({ hits }, hitComponent) => (
//   <div>
//     {hits.map(hit => (
//       <hitComponent hit={hit} />
//     ))}
//   </div>
// );

// const CustomHits = connectHits(Hits);

// const SearchBox = ({ currentRefinement, isSearchStalled, refine }) => (
//   <form noValidate action="" role="search">
//     <input
//       className="w-full h-10 inlie-block rounded-l-md border-2 border-solid border-gray px-4 py-2 w-96"
//       placeholder="試下搵 'SHARLO co-founder'"
//       type="search"
//       value={currentRefinement}
//       onChange={event => refine(event.currentTarget.value)}
//     />
//     {/* <button onClick={() => refine('')}>Reset query</button> */}
//     {isSearchStalled ? 'loading' : ''}
//   </form>
// );

const qs = (path) => {
  const urlSearchParams = new URLSearchParams(path);
  return Object.fromEntries(urlSearchParams.entries())
}

const urlToSearchState = path => {
  const params = qs(path)
  const category = params.category
  const query = params.query

  let searchState = {}

  if(query){
    searchState = {...searchState, query}
  }

  if(category){
    searchState = {...searchState, 
      refinementList: {
        category: [category],
    },}
  }
  return searchState
};


export default function SearchScreen(props) {
  const location = useLocation()


  const [searchState, setSearchState] = useState(
    urlToSearchState(location.search)
  )

  useEffect(() => {
    const nextSearchState = urlToSearchState(location.search)
    
    if (JSON.stringify(searchState) !== JSON.stringify(nextSearchState)) {
      setSearchState(nextSearchState);
    }

    // eslint-disable-next-line
  }, [location]);
  
  const onSearchStateChange = updatedSearchState => {
    // clearTimeout(debouncedSetState);

    // setDebouncedSetState(
    //   setTimeout(() => {
    //     history.pushState(
    //       updatedSearchState,
    //       null,
    //       searchStateToUrl(updatedSearchState)
    //     );
    //   }, DEBOUNCE_TIME)
    // );
    // if(updatedSearchState.query)
    setSearchState(updatedSearchState);
  };

  console.log('SearchScreen', searchState)

  let auth = useAuth()
  let data = props.bookingList.listOfData || []
  let bookingList = data.filter(b=>{
    return b.toId!==auth.user.uid && (b.status === 'pending' || b.status === 'accepted')
  }).map(x => {
    return (x.fromId===auth.user.uid)?x.toId:x.fromId
  })

  console.log('bookingList', bookingList)
  const searchClient = algoliasearch(
    '4LDF1LRH2G',
    'e57e5f1c43d9513c6ade091d773367a2'
  );
  // const CustomSearchBox = connectSearchBox(SearchBox);
  const VirtualSearchBox = connectSearchBox(()=>null);
  
  return (

    <div className="w-full">
      <CategorySection />
      <div className="max-w-screen-lg flex mt-4 mb-4 space-x-4 w-full mx-auto">
        <InstantSearch
          indexName="userProfile"
          searchClient={searchClient}
          searchState={searchState}
          onSearchStateChange={onSearchStateChange}
        >
          <Configure
            hitsPerPage={10}
            analytics={false}
            enablePersonalization={false}
            facets= {['objectID']}
            filters={`NOT objectID:${auth.user.uid} AND isAdviser:true`}
            distinct
          />
          <div className="w-3/12 bg-white rounded-md py-8 px-8 self-start space-y-2 sticky top-4">
            {/* <CurrentRefinements /> */}
            <div className='hidden'>
              <h4>分享類型</h4>
              <RefinementList attribute="category" />
            </div>
            <h3>熱門標籤</h3>
            <RefinementList attribute="tags"
            transformItems={items =>
              items.map(item => ({
                ...item,
                // label: item.label.toUpperCase(),
                count: `(${item.count})`
              }))
            }/>
            <h3>SHARLO認證</h3>
            <ToggleRefinement
              attribute="isVerified"
              label="已認證用戶"
              value={true}
            />
          </div>
          <div className="w-9/12 bg-white rounded-md">
            <div className="hidden"><SearchBox /></div>
            {searchState.query&&<h1 className="px-8 pt-8 mb-4">‘{searchState.query}’ 的搜尋結果</h1>}
            <Hits hitComponent={(hit=><Hit hit={hit.hit} bookingList={bookingList} />)} />    
            <Pagination />
          </div>
        </InstantSearch>

        {/* <h2>搜尋結果</h2>
        <div className="profile-feed">
          {profiles}
        </div> */}
      </div>
    </div>
  )
}