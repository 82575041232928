import { db } from '../service/api'
import { useAuth } from "../hooks/useAuth";
import firebase from "firebase/app";
import "firebase/firestore";
import { useState } from 'react';
import ConfirmBookingModal from './ConfirmBookingModal';

export default function ProfileListButtons(props){
  let user = props.user
  let bookingList = props.bookingList
  let auth = useAuth()
  const [isAddingBooking, setIsAddingBooking] = useState(false)
  const handleBooking = ()=>{}
  return(
    <div className="profile-action">
      {bookingList && !bookingList.includes(user.uid) && <button className="button fill bold" onClick={handleBooking} disabled={isAddingBooking}>立即預約</button>}
      {bookingList && bookingList.includes(user.uid) && <button className="button fill light bold" onClick={()=>{alert('已預約')}}>已預約</button>}
      <button className="button fill light bold">加至最愛</button>
      <div className="profile-stat">
      <span>🤙&nbsp;{user.popularity}</span><span>⭐&nbsp;{user.rating}/5</span>
      </div>
    </div>
  )
}