import {
  useState
} from 'react';

function useInput(val) {
  let [value, setValue] = useState(val);
  let [err, setError] = useState(null);

  const onChange = (event) => {
    const value = event.target.value
    setValue(value);
  };

  return {
    value,
    setValue,
    onChange,
    err,
    setError
  };

}

export default useInput;