import { XIcon } from '@heroicons/react/solid'

export default function Overlay(props){
  const overlay = props.overlay
  if(overlay.isOverlay){
    return(
      <div className="fixed flex justify-center align-center left-0 top-0 w-full h-full bg-black bg-opacity-50 z-50">
        <div className="self-center bg-white rounded-md p-8">
          <div className="flex justify-end"><XIcon className='h-6 w-6 cursor-pointer' onClick={()=>{overlay&&overlay.toggleOverlay()}} /></div>
          {overlay.overlayContent}
        </div>
      </div>
    )
  }else{
    return(<></>)
  }

}